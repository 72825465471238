import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ForgetPasswordAction, loginAdmin } from "../../Redux/Auth/AuthActions";

const ForgetPassword = () => {
  const [email, setEmail] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, isAuthenticated, responeData } = useSelector(
    (state) => state.Auth
  );

  useEffect(() => {
    if (isAuthenticated) navigate("/");
  }, [isAuthenticated, navigate]);

  const login = async (e) => {
    e.preventDefault();
    dispatch(ForgetPasswordAction(email));
  };

  useEffect(() => {
    console.log(responeData);
  });

  return (
    <Row className="Log">
      <Col>
        <Card className="CardStyle">
          <CardTitle tag="h6" className="border-bottom p-3 mb-0 Logtitle">
            <i className="bi bi-bell me-2"> </i>
            Forget Password
          </CardTitle>
          <CardBody>
            <Form onSubmit={(e) => login(e)}>
              <FormGroup>
                <Label for="exampleEmail" className="lableName">
                  Email
                </Label>
                <Input
                  id="exampleEmail"
                  name="email"
                  placeholder="Enter your Email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormGroup>

              {loading && <p>Loading ...</p>}

              {responeData?.data && (
                <p style={{ color: "green" }}>{responeData.data.message}</p>
              )}
              {error && <p style={{ color: "red" }}>{error.message}</p>}

              <Button type="submit">ForgetPassword</Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ForgetPassword;
