import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SnowshoeingIcon from '@mui/icons-material/Snowshoeing';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { AllInclusiveTwoTone, CompareRounded, ContactMail, DirectionsRailwayTwoTone, Error, GifBox, MoneyOffCsredRounded, PostAdd, VerifiedUserSharp } from '@mui/icons-material';


export const IconsAndData={
    "totalDonations": {
        icon:<DirectionsRailwayTwoTone  />,
        title:"Donations"
    },
    "totalAdmins": {
        icon:<AdminPanelSettingsIcon  sx={{fontSize: "26px"}}/>,
        title:"Admins"
    },
    "totalUsers": {
        icon:<GroupIcon/>,
        title:"Users"
    },
    "totalNgos":{
        icon:<AccountCircleIcon/>,
        title:"Ngos"
    },
    "totalCompanies":{
        icon:<ApartmentIcon/>,
        title:"Companies"
    },
    "TotalPaidCashouts":{
        icon:<AttachMoneyIcon/>,
        title:"Cashouts"
    },
    "TotalChallenges":{
        icon:<SnowshoeingIcon/>,
        title:"Challenges"
    },
    "TotalGiftChallenge":{
        icon:<CardGiftcardIcon/>,
        title:"Gift Challenges"
    },
    "TotalDonationChallenges":{
        icon:<GifBox/>,
        title:"Donation Challenges"
    },
    "TotalCompanyDonationChallenges":{
        icon:<CompareRounded/>,
        title:"Company Donations Challenges"
    },
    "ActiveUsersOnApp":{
        icon:<VerifiedUserSharp/>,
        title:"Active Users"
    },
    "TotalContactUs":{
        icon:<ContactMail/>,
        title:"Contact Us"
    },
    "TotalAppInvitations":{
        icon:<AllInclusiveTwoTone/>,
        title:"App Invitations"
    },
    "TotalPosts":{
        icon:<PostAdd/>,
        title:" Posts"
    },
    "TotalOrders":{
        icon:<MoneyOffCsredRounded/>,
        title:" Orders"
    },
    "TotalAppErrors":{
        icon:<Error/>,
        title:" Errors"
    },
}