import axios from "axios";
import { DonationActions } from "./DonationsReducer";

export const getDonations = () => async (dispatch) => {
    dispatch(DonationActions.DontaionRequest());
    try {
      let url = process.env.REACT_APP_API + "/Donation/getAllDonations";
      const response = await axios.get(url);
      dispatch(DonationActions.DonationSuccess(response.data));
    } catch (e) {
      dispatch(DonationActions.DonationFail(e.response));
    }
  };