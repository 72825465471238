import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    statistics: [],
};

const statisticsSlice = createSlice({
    name: "statistics",
    initialState,
    reducers: {
        fetchRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        fetchSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.statistics = action.payload;
        },

        fetchFail: (state, action) => {
            state.loading = false;
            state.statistics = [];
            state.error = action.payload;
        }
    },
});

export const StatisticsActions = statisticsSlice.actions;
export default statisticsSlice;
