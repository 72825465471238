import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    Privacy: [],
};

const PrivacySlice = createSlice({
    name: "privacy",
    initialState,
    reducers: {
        fetchRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        fetchSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.Privacy = action.payload;
        },
        clearSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        }, addconditionSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        }, deleteconditionSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        editconditionSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },


        fetchFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    },
});

export const PrivacyActions = PrivacySlice.actions;
export default PrivacySlice;
