import axios from "axios";
import { DonationChallengeActions } from "./DonationChallengeReducer";

export const getDonationChallenge = () => async (dispatch) => {

    dispatch(DonationChallengeActions.DonationChallengeRequest());
    try{
        let url =
        process.env.REACT_APP_API +
        "/Challenge/getallchallengesbyAdmin?donationChallenge=true";
        const response = await axios.get(url);
        dispatch(DonationChallengeActions.DonationChallengeSuccess(response));

    }catch(err){
        dispatch(DonationChallengeActions.DonationChallengeFail(err));
    }

}

export const approveDonationChallenge = (id) => async (dispatch) => {
    console.log({ID_IN_APPROVE_CHALLENGE:id})
    dispatch(DonationChallengeActions.DonationChallengeRequest());
    try{
        let url =
            process.env.REACT_APP_API + "/Challenge/adminApprovingChallenge/" + id;
        const response = await axios.put(url);
        dispatch(DonationChallengeActions.DonationChallengeSuccess(response.data));
        dispatch(getDonationChallenge());
    }catch(err){
        dispatch(DonationChallengeActions.DonationChallengeFail(err));
    }
}

export const addDonationChallenge = (
    image,
    goal,
    timestart,
    timeend,
    description,
    title,
    ngo_id,
    amount,
    is_sponsored,
    sponsoredby,
    sponsored_image) => async (dispatch) => {

    const formData = new FormData();

    formData.append("image", image);
    formData.append("goal", goal);
    formData.append("timestart", timestart);
    formData.append("timeend", timeend);
    formData.append("description", description);
    formData.append("title", title);
    formData.append("ngo_id", ngo_id);

    formData.append("amount", amount);
    formData.append("is_sponsored", is_sponsored);
    formData.append("sponsoredby", sponsoredby);
    formData.append("sponsored_image", sponsored_image);
    

    // Log the parameters before making the API call
    console.log("Add Donation Challenge Parameters:", {
        image,
        goal,
        timestart,
        timeend,
        description,
        title,
        ngo_id,
        amount,
        is_sponsored,
        sponsoredby,
        sponsored_image
    });

    dispatch(DonationChallengeActions.DonationChallengeRequest());
    try{
        const response = await axios.post(
            process.env.REACT_APP_API + "/Challenge/createDonationChallenge",
            formData
        );
        console.log(response);
        dispatch(DonationChallengeActions.DonationChallengeSuccess());
        dispatch(getDonationChallenge());

    }catch(err){
        dispatch(DonationChallengeActions.DonationChallengeFail(err));
    }
}

export const deleteDonationChallenge = (id) => async (dispatch) => {

    console.log('deleteDonationChallenge', id);
    dispatch(DonationChallengeActions.DonationChallengeRequest());
    try {
        await axios.delete(
          process.env.REACT_APP_API + "/Challenge/deletechallengebyid/" + id
        );
        dispatch(DonationChallengeActions.DonationChallengeSuccess());
        dispatch(getDonationChallenge());
      } catch (e) {
        dispatch(DonationChallengeActions.DonationChallengeFail(e));
      }
}


export const updateDonationChallenge = (
                id,
                goal,
                timestart,
                timeend,
                description,
                title,
                ispublic,
                is_approved,
                image,
                )=>async(dispatch)=>{

                    const formData = new FormData();
                    formData.append("goal", goal)
                    formData.append("timestart", timestart);
                    formData.append("timeend", timeend);
                    formData.append("description", description);
                    formData.append("title", title)
                    formData.append("ispublic", ispublic==="Not Public"?false :true);
                    formData.append("is_approved", is_approved === "Approved" ? true : false);
                    
                    if(!(image instanceof File)){
                        image = await urlToFile(image, "image.jpg");
                        formData.append("image", image);
                    }
                    else{
                        formData.append("image", image);
                    }

                    const token = localStorage.getItem("jwtToken");
                    

                    dispatch(DonationChallengeActions.DonationChallengeRequest());
                    try{
                        await axios.put(
                            process.env.REACT_APP_API + "/Challenge/updatechallengebyid/" + id,
                                formData,
                                {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                        },
                                
                                }
                        );
                        dispatch(DonationChallengeActions.DonationChallengeSuccess());
                        dispatch(getDonationChallenge());

                    }catch(err){
                        dispatch(DonationChallengeActions.DonationChallengeFail(err));
                    }
                }

async function urlToFile(imageUrl, fileName) {
    // Fetch the image
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    console.log(blob)
    // Create a File object
    const file = new File([blob], fileName, { type: blob.type });
    
    return file;
}