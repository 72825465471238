import axios from "axios";
import { GiftChallengeAction } from "./giftChallengeReducer";

export const getGiftChallenges = () => async (dispatch) => {
  try {
    dispatch(GiftChallengeAction.fetchGiftChallengeRequest());

    const res = await axios.get(
      process.env.REACT_APP_API +
        "/Challenge/getallchallengesbyAdmin?isApproved=true&giftChallenge=true&AdminGift=true"
    );

    dispatch(GiftChallengeAction.fetchGiftChallengeSuccess(res));
  } catch (error) {
    dispatch(GiftChallengeAction.fetchGiftChallengeFail(error));
  }
};
export const AddGiftChallenge =
  (
    image,
    goal,
    timestart,
    timeend,
    description,
    title,
    giftcode,
    emaildescription,
    winnerNumber
  ) =>
  async (dispatch) => {
    dispatch(GiftChallengeAction.addGiftChallengeRequest());
    const token = localStorage.getItem("jwtToken");

    const formData = new FormData();

    formData.append("image", image);
    formData.append("goal", goal);
    formData.append("timestart", timestart);
    formData.append("timeend", timeend);
    formData.append("description", description);
    formData.append("title", title);
    formData.append("giftcode", giftcode);
    formData.append("emaildescription", emaildescription);
    formData.append("winnerNumber", winnerNumber);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "/Challenge/CreateGiftChallenge",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      dispatch(GiftChallengeAction.addGiftChallengeSuccess(response.data));
      return response;
    } catch (e) {
      dispatch(GiftChallengeAction.addGiftChallengeFail(e.response.data));
    }
  };

export const addWinnerReward =
  (challenge_id, name, description, type, image) => async (dispatch) => {
    console.log("123", challenge_id, name, description, type, image);
    dispatch(GiftChallengeAction.addRewardRequest());

    const token = localStorage.getItem("jwtToken");
    const formData = new FormData();

    formData.append("name", name);
    formData.append("description", description);
    formData.append("type", type);
    formData.append("image", image);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API + "/Challenge/createReward/" + challenge_id,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(GiftChallengeAction.addRewardSuccess(response.data));
    } catch (e) {
      dispatch(GiftChallengeAction.addRewardFail(e.response.data));
    }
  };

export const addParticipantReward =
  (challenge_id, amount, type) => async (dispatch) => {
    dispatch(GiftChallengeAction.addParticipantRewardRequest());
    const token = localStorage.getItem("jwtToken");
    try {
      await axios.post(
        process.env.REACT_APP_API + "/Challenge/createReward/" + challenge_id,
        {
          amount,
          type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(GiftChallengeAction.addParticipantRewardSuccess());
      dispatch(getGiftChallenges());
    } catch (e) {
      dispatch(GiftChallengeAction.addParticipantRewardFail(e.response.data));
    }
  };

export const updateGiftChallenge =
  (
    id,
    image,
    goal,
    timestart,
    timeend,
    description,
    title,
    giftcode,
    emaildescription,
    winnerNumber
  ) =>
  async (dispatch) => {
    dispatch(GiftChallengeAction.updateGiftChallengeRequest());
    const token = localStorage.getItem("jwtToken");
    const formData = new FormData();

    formData.append("image", image);
    formData.append("goal", goal);
    formData.append("timestart", timestart);
    formData.append("timeend", timeend);
    formData.append("description", description);
    formData.append("title", title);
    formData.append("giftcode", giftcode);
    formData.append("emaildescription", emaildescription);
    formData.append("winnerNumber", winnerNumber);
    try {
      await axios.put(
        process.env.REACT_APP_API + "/Challenge/updatechallengebyid/" + id,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(GiftChallengeAction.updateGiftChallengeSuccess());
      dispatch(getGiftChallenges());
    } catch (e) {
      dispatch(GiftChallengeAction.updateGiftChallengeFail(e.response.data));
    }
  };
export const deleteGiftChallenge = (id) => async (dispatch) => {
  dispatch(GiftChallengeAction.deleteGiftChallengeRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    //id?
    await axios.delete(
      process.env.REACT_APP_API + "/Challenge/deletechallengebyid/" + id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(GiftChallengeAction.deleteGiftChallengeSuccess());
    dispatch(getGiftChallenges());
  } catch (e) {
    dispatch(GiftChallengeAction.deleteGiftChallengeFail(e.response.data));
  }
};
