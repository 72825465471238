import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  giftchallenges: [],
  addGiftChallenge: [],
  addRewards: [],
  updateGiftChallenge: [],
  deleteGiftChallenge: [],
};

const giftChallengeSlice = createSlice({
  name: "GiftChallenge",
  initialState,
  reducers: {
    fetchGiftChallengeRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    fetchGiftChallengeSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.giftchallenges = action.payload;
    },

    fetchGiftChallengeFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    addGiftChallengeRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addGiftChallengeSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addGiftChallenge = action.payload;
    },
    addGiftChallengeFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    addRewardRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addRewardSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addRewards = action.payload;
    },

    addParticipantRewardFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    
    addParticipantRewardRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addParticipantRewardSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addRewards = action.payload;
    },

    addRewardFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateGiftChallengeRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateGiftChallengeSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updateGiftChallenge = action.payload;
    },

    updateGiftChallengeFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteGiftChallengeRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deleteGiftChallengeSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteGiftChallenge = action.payload;
    },

    deleteGiftChallengeFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const GiftChallengeAction = giftChallengeSlice.actions;
export default giftChallengeSlice;
