import axios from "axios";
import { DepartmentActions } from "./DepartmentReducers";

export const getDepartment = () => async (dispatch) => {
    dispatch(DepartmentActions.DepartmentRequest());
    try {
        let url = process.env.REACT_APP_API + "/department/getall";
        const response = await axios.get(url);
        dispatch(DepartmentActions.DepartmentSuccess(response));
    } catch (e) {
        dispatch(DepartmentActions.DepartmentFail(e.response));
    }
};


export const addDepartment = (name) => async (dispatch) => {
    dispatch(DepartmentActions.DepartmentRequest());
    try {
        await axios.post(process.env.REACT_APP_API + "/department/create", {
            name,
        });
        dispatch(DepartmentActions.addDepartmentSuccess());
        dispatch(getDepartment());
    } catch (e) {
        dispatch(DepartmentActions.DepartmentFail(e.response.data));
    }
};


export const deleteDepartment = (id) => async (dispatch) => {
    dispatch(DepartmentActions.DepartmentRequest());
    try {
        await axios.delete(process.env.REACT_APP_API + "/department/delete/" + id);
        dispatch(DepartmentActions.deleteDepartmentSuccess());
        dispatch(getDepartment());
    } catch (e) {
        dispatch(DepartmentActions.DepartmentFail(e.response.data));
    }
};



export const updateDepartment = (id, name) => async (dispatch) => {
    dispatch(DepartmentActions.DepartmentRequest());
    try {
        await axios.put(process.env.REACT_APP_API + "/department/update/" + id, {
            name,
        });
        dispatch(DepartmentActions.editDepartmentSuccess());
        dispatch(getDepartment());
    } catch (e) {
        dispatch(DepartmentActions.DepartmentFail(e.response.data));
    }
};


export const clear = () => (dispatch) => {
    dispatch(DepartmentActions.clearSuccess());
};
