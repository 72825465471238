import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    Charity: [],
};

const CharitySlice = createSlice({
    name: "Charity",
    initialState,
    reducers: {
        CharityRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        CharitySuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.Charity = action.payload;
        },
        editCharitySuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        }, addCharitySuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        }, deleteCharitySuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },


        CharityFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    },
});

export const CharityActions = CharitySlice.actions;
export default CharitySlice;
