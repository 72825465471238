import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../../index.css";
import Modal from "react-modal";
import isAuth from "../../Utils/isAuth";
import { useNavigate } from "react-router-dom";
import {
  addImage,
  addProduct,
  deleteImage,
  deleteProduct,
  editMarket,
  getMarket,
} from "../../Redux/Market/MarketActions";

const Market = () => {
  const dispatch = useDispatch();
  const { Market, loading, error } = useSelector((state) => state.Market);
  const navigate = useNavigate();
  const [Images, setImages] = useState([]);
  const [ImagesModel, setImagesModel] = useState(false);

  const [price, setprice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setdescription] = useState("");
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [ordercode, setOrdercode] = useState("");
  const [orderdescription, setOrderdescription] = useState("");
  const [timestart, setTimestart] = useState();
  const [timeend, setTimeend] = useState();
  const [stockamount, setStockamount] = useState("");
  const [additionalImages, setAdditionalImages] = useState([]);
  const [addImageData, setAddImageData] = useState(null);

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getMarket());
  }, [dispatch]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (Market) {
      setData(Market?.marketplaceProducts?.rows);
    }
  }, [Market]);

  console.log(data);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");

  const deleteplanhandle = (id) => {
    dispatch(deleteProduct(id));
    setModalIsOpen(false);
    setId("");
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [addmodalIsOpen, setaddModalIsOpen] = useState(false);

  const addopenModal = () => {
    setaddModalIsOpen(true);
  };

  const DeleteImageByID = (id) => {
    dispatch(deleteImage(id));
  };

  const addcloseModal = () => {
    setaddModalIsOpen(false);
    setedutModalIsOpen(false);
    setName();
    setprice();
    setdescription();
    setTimeend();
    setTimestart();
    setAbout();
    setAdditionalImages([]);
    setOrdercode();
    setOrderdescription();
    setStockamount();
    setCategory();
  };
  const addplanhandle = (
    name,
    price,
    description,
    category,
    ordercode,
    orderdescription,
    timestart,
    timeend,
    stockamount,
    about
  ) => {
    if (timestart > timeend) {
      alert("Timestart must be greater than End Time");
      return;
    } else {
      dispatch(
        addProduct(
          name,
          price,
          description,
          category,
          ordercode,
          orderdescription,
          timestart,
          timeend,
          stockamount,
          about,
          additionalImages
        )
      );

      setaddModalIsOpen(false);
      addcloseModal();
    }
  };

  const [edutmodalIsOpen, setedutModalIsOpen] = useState(false);

  const editopenModal = () => {
    setedutModalIsOpen(true);
  };

  const editcloseModal = () => {
    setedutModalIsOpen(false);
  };
  const editplanhandle = (
    id,
    name,
    price,
    description,
    category,
    ordercode,
    orderdescription,
    timestart,
    timeend,
    stockamount,
    about
  ) => {
    if (timestart > timeend) {
      alert("Timestart must be greater than End Time");
      return;
    } else {
      dispatch(
        editMarket(
          id,
          name,
          price,
          description,
          category,
          ordercode,
          orderdescription,
          timestart,
          timeend,
          stockamount,
          about,
          additionalImages
        )
      );
      setedutModalIsOpen(false);
      addcloseModal();
    }
  };

  const AddImage = (id, img) => {
    console.log("before Action ]: ", img);
    dispatch(addImage(id, img));
    setImagesModel(false);
  };
  const handleAdditionalImagesChange = (e) => {
    setAdditionalImages((prevImages) => [...prevImages, ...e.target.files]);
  };

  const removeImageFunction = (imageId) => {
    dispatch(deleteImage(id, imageId));
    setImagesModel(false);
  };

  const columns = [
    { field: "name", headerName: "Name" },
    { field: "description", headerName: "Description" },
    { field: "price", headerName: "Price" },
    {
      field: "images",
      headerName: "Images",
      width: 150,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-carousel-modal"
              onClick={() => {
                setImages(record.row.product_images);
                setId(record.row.id);
                setImagesModel(true);
              }}
            >
              Images
            </button>
          </Fragment>
        );
      },
    },
    { field: "category", headerName: "Category", width: 200 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-carousel-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setName(record.row.name);
                setprice(record.row.price);
                setdescription(record.row.description);
                let DateObject = new Date(record.row.timestart);
                let isoString = DateObject.toISOString();
                let formattedDate = isoString.split("T")[0];
                setTimestart(formattedDate);
                DateObject = new Date(record.row.timeend);
                isoString = DateObject.toISOString();
                formattedDate = isoString.split("T")[0];
                setTimeend(formattedDate);
                setAbout(record.row.about);
                setCategory(record.row.category);
                setAdditionalImages([record.row.product_images]);
                setOrdercode(record.row.ordercode);
                setOrderdescription(record.row.orderdescription);
                setStockamount(record.row.stockamount);
                setImages(record.row.product_images);
                editopenModal();
              }}
            >
              Edit
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions1"
              onClick={() => {
                setId(record.row.id);
                setTimestart(record.row.timestart);
                openModal();
              }}
            >
              Delete
            </button>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* Edit Model */}
      <Modal
        isOpen={edutmodalIsOpen}
        onRequestClose={editcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit modal"
        setAppElement={"div"}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Product</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Name:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={name}
                  placeholder="Enter the Name"
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <h6>price:</h6>
                <input
                  type="number"
                  className="  inputt col-md-8 form-control cat"
                  value={price}
                  placeholder="Enter the price"
                  required
                  onChange={(e) => {
                    setprice(e.target.value);
                  }}
                />
                <h6>Description:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={description}
                  placeholder="Enter the Description"
                  required
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                />
                <h6>Category:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={category}
                  placeholder="Enter the Category"
                  required
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <h6>Ordercode:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={ordercode}
                  placeholder="Enter the OrderCode"
                  required
                  onChange={(e) => {
                    setOrdercode(e.target.value);
                  }}
                />
                <h6>Order Description:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={orderdescription}
                  placeholder="Enter the price"
                  required
                  onChange={(e) => {
                    setOrderdescription(e.target.value);
                  }}
                />
                <h6>Start Time:</h6>
                <input
                  type="date"
                  className="  inputt col-md-8 form-control cat"
                  value={timestart}
                  placeholder="Enter the Date"
                  required
                  onChange={(e) => {
                    setTimestart(e.target.value);
                  }}
                />
                <h6>End Time:</h6>
                <input
                  type="date"
                  className="  inputt col-md-8 form-control cat"
                  value={timeend}
                  placeholder="Enter the Date"
                  required
                  onChange={(e) => {
                    setTimeend(e.target.value);
                  }}
                />
                <h6>Stock Amount:</h6>
                <input
                  type="number"
                  className="  inputt col-md-8 form-control cat"
                  value={stockamount}
                  placeholder="Enter the Amount"
                  required
                  onChange={(e) => {
                    setStockamount(e.target.value);
                  }}
                />
                <h6>About:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={about}
                  placeholder="Enter the About"
                  required
                  onChange={(e) => {
                    setAbout(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={editcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() =>
              editplanhandle(
                id,
                name,
                price,
                description,
                category,
                ordercode,
                orderdescription,
                timestart,
                timeend,
                stockamount,
                about
              )
            }
            className="buttoninmodal1"
          >
            Edit Product
          </button>
        </div>
      </Modal>
      {/* Add Model */}
      <Modal
        isOpen={addmodalIsOpen}
        onRequestClose={addcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Add modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 10000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Product</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Name:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={name}
                  placeholder="Enter the Name"
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <h6>price:</h6>
                <input
                  type="number"
                  className="  inputt col-md-8 form-control cat"
                  value={price}
                  placeholder="Enter the price"
                  required
                  onChange={(e) => {
                    setprice(e.target.value);
                  }}
                />
                <h6>Description:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={description}
                  placeholder="Enter the Description"
                  required
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                />
                <h6>Category:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={category}
                  placeholder="Enter the Category"
                  required
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
                <h6>Ordercode:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={ordercode}
                  placeholder="Enter the OrderCode"
                  required
                  onChange={(e) => {
                    setOrdercode(e.target.value);
                  }}
                />
                <h6>Order Description:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={orderdescription}
                  placeholder="Enter the price"
                  required
                  onChange={(e) => {
                    setOrderdescription(e.target.value);
                  }}
                />
                <h6>Start Time:</h6>
                <input
                  type="date"
                  className="  inputt col-md-8 form-control cat"
                  value={timestart}
                  placeholder="Enter the Date"
                  required
                  onChange={(e) => {
                    setTimestart(e.target.value);
                  }}
                />
                <h6>End Time:</h6>
                <input
                  type="date"
                  className="  inputt col-md-8 form-control cat"
                  value={timeend}
                  placeholder="Enter the Date"
                  required
                  onChange={(e) => {
                    setTimeend(e.target.value);
                  }}
                />
                <h6>Stock Amount:</h6>
                <input
                  type="number"
                  className="  inputt col-md-8 form-control cat"
                  value={stockamount}
                  placeholder="Enter the Amount"
                  required
                  onChange={(e) => {
                    setStockamount(e.target.value);
                  }}
                />
                <h6>About:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={about}
                  placeholder="Enter the About"
                  required
                  onChange={(e) => {
                    setAbout(e.target.value);
                  }}
                />

                <h6 className="description">
                  Images ( You can add many images )
                </h6>
                <input
                  type="file"
                  multiple
                  onChange={handleAdditionalImagesChange}
                />
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={addcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() =>
              addplanhandle(
                name,
                price,
                description,
                category,
                ordercode,
                orderdescription,
                timestart,
                timeend,
                stockamount,
                about
              )
            }
            className="buttoninmodal1"
          >
            Add Product
          </button>
        </div>
      </Modal>
      {/* Delete row */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="delete modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40rem",
            height: "15rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Delete Product</h2>
          <h5>Are You Sure You Want To Delete This Product ?</h5>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <button onClick={closeModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => deleteplanhandle(id)}
            className="buttoninmodal1"
          >
            Delete
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={ImagesModel}
        onRequestClose={() => setImagesModel(false)}
        shouldCloseOnOverlayClick={false}
        contentLabel="Images"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Images</h2>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              margin: "2rem",
              gap: "2rem",
            }}
          >
            {Images?.map((item) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  gap: "2rem",
                }}
              >
                <img
                  width={200}
                  height={100}
                  src={item.image}
                  alt="ImageNotFound"
                />
                <button
                  style={{ width: "5rem", height: "4rem" }}
                  onClick={() => {
                    removeImageFunction(item.id);
                  }}
                >
                  Remove
                </button>
              </Box>
            ))}
          </Box>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <button
            onClick={() => {
              setImagesModel(false);
              setImages();
            }}
            className="buttoninmodal"
          >
            Close
          </button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <label for="imageInput">Add Image</label>
            <input
              id="imageInput"
              type="file"
              accept="image/*"
              onChange={(e) => {
                setAddImageData(e.target.files[0]);
              }}
            />
            <button onClick={() => AddImage(id, addImageData)}>
              Add Image
            </button>
          </Box>
        </div>
      </Modal>
      <Header
        title="Market Place"
        buttonText="Add Product"
        onopen={addopenModal}
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && data && (
          <DataGrid
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: "16px", // Adjust the font size as needed
                color: "black",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold", // Adjust the font size as needed
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default Market;
