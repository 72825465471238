import axios from "axios";
import { ContactAction } from "./ContactReducers";

export const getcontactus = () => async (dispatch) => {
  dispatch(ContactAction.ContactRequest());
  try {
    let url = process.env.REACT_APP_API + "/ContactUs/getAllbytype";
    const response = await axios.get(url);
    dispatch(ContactAction.ContactSuccess(response.data));
  } catch (e) {
    dispatch(ContactAction.ContactFail(e.response));
  }
};


