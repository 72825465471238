import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    Users: [],
};

const UsersSlice = createSlice({
    name: "Users",
    initialState,
    reducers: {
        fetchRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        fetchSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.Users = action.payload;
        },
        deleteUserSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        clearSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },

        fetchFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    },
});

export const UsersActions = UsersSlice.actions;
export default UsersSlice;
