import React, { useState, useEffect, Fragment } from "react";
import { Box } from '@mui/material';
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getDonationChallenge,approveDonationChallenge,deleteDonationChallenge,addDonationChallenge, updateDonationChallenge } from "../../Redux/DonationChallenge/DonationChallengeAction";
import { getNgo } from "../../Redux/Ngo/NgoActions";
import { tokens } from "../../theme"
import { useTheme } from "@mui/material";
import Loading from '../../components/Loading';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from '../../components/Header';
//utils
import isAuth from "../../Utils/isAuth";

export default function DonationChallenge(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {loading,donationChallenge,error} = useSelector((state)=>state.DonationChallenge)
    const {ngos}=useSelector((state)=>state.Ngo)

    useEffect(()=>{
        if(!isAuth(dispatch)){
            navigate("/login");
        }
    },[dispatch,navigate])

   
    useEffect(()=>{
        dispatch(getDonationChallenge());
        dispatch(getNgo())
    },[dispatch])

    const [image, setImage] = useState();
    const [name,setName] = useState();
    const [goal, setGoal] = useState("");
    const [timestart, setTimeStart] = useState("");
    const [timeend, setTimeEnd] = useState("");
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [ngo_id, setNgoId] = useState("");
    const [amount, setAmount] = useState(0);
    const [is_sponsored, setIsSponsored] = useState(false);
    const [sponsoredby, setSponsoredBy] = useState("");
    const [sponsored_image, setSponsoredImage] = useState();
    const [is_approved, setIsApproved] = useState(false);
    const [ispublic, setIsPublic] = useState(false);
    const [id, setId] = useState("");

    const [records, setRecords] = useState([]);
    const[IsOpenDeleteModal,setIsOpenDeleteModal] = useState(false);
    const OpenDeleteModal = ()=>{
      setIsOpenDeleteModal(true);
    }
    const CloseDeleteModal = ()=>{
      setIsOpenDeleteModal(false);
    }
    const DeleteDonationChallenge=()=>{
      dispatch(deleteDonationChallenge(id));
      clear()
      CloseDeleteModal();
    }

    // EDIT MODAL FUNCTIONS

    const [IsOpenEditModal, setIsOpenEditModal] = useState(false);
    const OpenEditModal = () => {
      TimeFormat(timestart)
      setIsOpenEditModal(true);
    
    }
    const CloseEditModal = () => {
      clear()
      setIsOpenEditModal(false);
    }

    const EditDonationHandler=() => {
      
      
      dispatch(updateDonationChallenge(
                id,
                goal,
                timestart,
                timeend,
                description,
                title,
                ispublic,
                is_approved,
                image,
      ))

      setId();
      setImage("");
      setGoal("");
      setTimeStart("");
      setTimeEnd("");
      setDescription("");
      setTitle("");
      setNgoId("");
      setAmount(0);
      setIsSponsored(false);
      setSponsoredBy("");
      setSponsoredImage("");

      CloseEditModal();
    }
    //----------------------------------------------------------------

    // ADD MODAL FUNCTIONS
    const [IsOpenAddModal, setIsOpenAddModal] = useState(false);
    const OpenAddModal = () => {
      setIsOpenAddModal(true);
    }
    const CloseAddModal = () => {
      clear();
      setIsOpenAddModal(false);
    }

    const AddDonationHandler = () => {
      dispatch(
        addDonationChallenge(
          image,
          goal,
          timestart,
          timeend,
          description,
          title,
          ngo_id,
          amount,
          is_sponsored,
          sponsoredby,
          sponsored_image
        )
      );
  
      setImage("");
      setGoal("");
      setTimeStart("");
      setTimeEnd("");
      setDescription("");
      setTitle("");
      setNgoId("");
      setAmount(0);
      setIsSponsored(false);
      setSponsoredBy("");
      setSponsoredImage("");
  
      CloseAddModal();
    };
    //----------------------------------------------------------------

    const handleSetImage = (event) => {
      console.log({event:event.target.files[0]})
      setImage(event.target.files[0]);
      
    };
    const handleSetImageSP = (event) => {
      setSponsoredImage(event.target.files[0]);
    };
    const ChangeSponsoredHandler = (event) => {
      setIsSponsored(event.target.checked);
    };

    
    //FILL RECORDS WITH DATA 
    useEffect(() => {
        if (donationChallenge?.data?.challenges) {
          console.log({CHALLENGES:donationChallenge?.data?.challenges})
          setRecords(
            donationChallenge.data.challenges.map((Donationch) => {
              
              return {
                key: Donationch.id,
                id: Donationch.id,
                iscompleted: Donationch.iscompleted ? "Completed" : "Not Completed",
                goal: Donationch.goal,
                amount: Donationch.donationchallenge.amount,
                timestart: TimeFormat(Donationch.timestart),
                timeend: TimeFormat(Donationch.timeend),
                description: Donationch.description,
                title: Donationch.title,
                image: Donationch.image,
                is_approved: Donationch.is_approved ? "Approved" : "Pending",
                ispublic: Donationch.ispublic ? "Public" : "Not Public",
                name: Donationch.user.ngo.name,
                sponsored_image: Donationch.sponsored_image,
              };
            })
          );
        }
      },[donationChallenge]);

    const approveChallenge = (id) => {
        dispatch(approveDonationChallenge(id));
    }

    function clear(){
        setImage(null);
        setName("");
        setGoal("");
        setTimeStart("");
        setTimeEnd("");
        setDescription("");
        setTitle("");
        setNgoId("");
        setAmount(0);
        setIsSponsored(false);
        setSponsoredBy("");
        setSponsoredImage(null);
        setIsApproved(false);
        setIsPublic(false);
        setId("");
    }
    
    const columns = [
      {
        field: "profile",
        headerName: "Profile",
        flex:1.3,
  
        renderCell: (record) => {
          return (
            <div className="d-flex align-items-center gap-2">
              <img
                src={record?.row.image}
                className="rounded-circle"
                alt="avatar"
                width="45"
                height="45"
              />
              <h6>{record?.row.title}</h6>&nbsp;&nbsp;
            </div>
          );
        },
      },

      {
        field: "title",
        headerName: "Title",
        flex:1,
        renderCell: (record) => {
          return (
            <div className="d-flex pt-4" >
              <div>
                <h6 className="mb-0">{record?.row.name}</h6>
                <span className="text-muted">Goal : {record?.row.goal}</span>
                <br></br>
                <br></br>
              </div>
            </div>
          );
        },
      },
      {
        field: "time",
        headerName: "Time",
        flex:1,
        renderCell: (record) => {
          return (
            <div className="d-flex pt-4">
              <div>
                <h6 className="mb-0">{record?.row.timestart}</h6>
                <span className="text-muted">{record?.row.timeend}</span>
                <br></br>
                <br></br>
              </div>
            </div>
          );
        },
      },
  
      {
        field: "status",
        headerName: "Status",
        flex:1,
        renderCell: (record) => {
          return (
            <div className="d-flex align-items-center p-2">
              <div className="ms-3">
                <span className="text-muted">{record?.row.ispublic}</span>
                <br></br>
                <span className="text-muted">{record?.row.iscompleted}</span>
                <br></br>
              </div>
            </div>
          );
        },
      },
      {
        field: "approved",
        headerName: "Approved",
        flex:1,
        renderCell:(record)=> {
          return (
            <div className="d-flex align-items-center p-2">
              <div className="ms-3">
                <button
                  onClick={() => approveChallenge(record?.row?.id)}
                  className={
                    record?.row?.is_approved === "Approved"
                      ? "greenButton"
                      : "redButton"
                  }
                >
                  {record?.row?.is_approved}
                </button>
              </div>
            </div>
          );
        },
      },
  
      {
        field: "id",
        headerName: "Action",
        flex:1,
        renderCell: (record) => (
          <>
            <button
              className="btn btn-success"
              onClick={() => {

                  setId(record.row?.id);
                  setName(record.row?.name);
                  setGoal(record.row?.goal);
                  setAmount(record.row?.amount);
                  setTimeStart(record.row?.timestart);
                  setTimeEnd(record.row?.timeend);
                  setDescription(record.row?.description);
                  setTitle(record.row?.title);
                  setIsPublic(record.row?.ispublic);
                  setIsApproved(record.row?.is_approved);
                  setImage(record?.row?.image)
                  OpenEditModal();
              }}
            >
              Edit
            </button>
            &nbsp;
            <button
              className="btn btn-danger"
              onClick={() => {
                setId(record.id);
                OpenDeleteModal()
              }}
            >
              Delete
            </button>
          </>
        ),
      },
    ];

    
    function TimeFormat(time){
      var doubleDots = time.indexOf(":");
      var newTime = time.slice(0,doubleDots-3);
      return newTime ; 
    }
    

    return (
      
        <Box m="20px">
          {/* ADD MODAL */}
            <Modal
            isOpen={IsOpenAddModal}
            onRequestClose={CloseAddModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Add modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "50%",
                height: "30rem",

                margin: "0 auto",
                padding: "40px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Add</h2>
              <form>
                  <div className="modal-body">
                    <h4 className="description">Image</h4>
      
                    <div className="row formRow">
                      <input type="file" accept="image/*"  onChange={handleSetImage} />
      
                      {image && (
                        <img
                          src={image}
                          alt="Uploaded photo"
                          style={{
                            width: "300px",
                            height: "200px",
                            marginTop: "10px",
                          }}
                        />
                      )}
                    </div>

                    <br></br>
                    <h4 className="description">Info</h4>
                    <div className="row formRow">
                      <h6>Ngo :</h6>
                      <select
                        
                        onChange={(e) => setNgoId(e.target.value)}
                        required
                        defaultValue={name}
                        className="selectg"
                      >
                        <option value="" disabled>
                          Please select Ngo
                        </option>
                        {ngos.data?.ngos?.rows.map((ngo) => (
                          <option key={ngo.user_id} value={ngo.user_id}>
                            {ngo.name}
                          </option>
                        ))}
                      </select>
                    </div>
      
                    <br></br>
                    <div className="row formRow">
                      <h6>Goal :</h6>
      
                      <input
                        type="number"
                        className="  inputt col-md-8 form-control cat"
                        placeholder="Enter the Goal"
                        required
                        value={goal}
                        onChange={(e) => {
                          setGoal(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <h6>Amount :</h6>
      
                      <input
                        type="number"
                        className="  inputt col-md-8 form-control cat"
                        placeholder="Enter the Amount"
                        required
                        value={amount}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                      />
                    </div>
                    <br />
      
                    <div className="row formRow">
                      <h6 className="col-md-3 textForm">Title :</h6>
      
                      <input
                        type="text"
                        className="  inputt col-md-8 form-control cat"
                        placeholder="Enter the Title"
                        value={title}
                        required
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
                    <div className="row formRow">
                      <h6>Description :</h6>
      
                      <input
                        type="text"
                        className="  inputt col-md-8 form-control cat"
                        placeholder="Enter the Description"
                        required
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
                    <div className="row formRow">
                      <h6>Start :</h6>
      
                      <input
                        type="date"
                        className="  inputt col-md-8 form-control cat"
                        required
                        value={timestart}
                        onChange={(e) => {
                          setTimeStart(e.target.value);
                        }}
                        max={timeend}
                      />
                    </div>
                    <br></br>
                    <div className="row formRow">
                      <h6>End :</h6>
      
                      <input
                        type="date"
                        className="  inputt col-md-8 form-control cat"
                        min={timestart}
                        value={timeend}
                        required
                        onChange={(e) => {
                          setTimeEnd(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
      
                    <div className="row formRow">
                      <label className="checkboxLabel">
                        <input
                          type="checkbox"
                          checked={ispublic === "Not Public" ? false : true}
                          onChange={(e)=>{
                            console.log({ispublic: ispublic})
                            setIsPublic((e.target.checked) === false ? "Not Public" : "Public")}}
                        />
                        <span className="checkboxText " style={{paddingLeft:"10px"}}>Public</span>
                      </label>

                      <label className="checkboxLabel">
                        <input
                          type="checkbox"
                          checked={is_approved === "Pending" ? false : true}
                          onChange={(e)=>{
                            setIsApproved((e.target.checked) === false ? "Pending" : "Approved")}}
                        />
                        <span className="checkboxText " style={{paddingLeft:"10px"}}>Approved</span>
                      </label>
                    </div>

                    <br></br>
                  </div>
              </form>
              <br></br>
            </div>

            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <button onClick={CloseAddModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() =>
                  AddDonationHandler(
                  )
                }
                className="buttoninmodal1"
              >
                Add Challenge
              </button>
            </div>
            </Modal>
          {/* ------------------------- */}


          {/* EDIT MODAL  */}
          <Modal
            isOpen={IsOpenEditModal}
            onRequestClose={CloseEditModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Add modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "50%",
                height: "30rem",

                margin: "0 auto",
                padding: "40px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Edit</h2>
              <form>
                  <div className="modal-body">
                    <h4 className="description">Image</h4>
      
                    <div className="row formRow">
                      <input type="file" accept="image/*"  onChange={handleSetImage} />
      
                      {image && (
                        <img
                          src={image}
                          alt="Uploaded photo"
                          style={{
                            width: "300px",
                            height: "200px",
                            marginTop: "10px",
                          }}
                        />
                      )}
                    </div>

                    <br></br>
                    <h4 className="description">Info</h4>
                    <div className="row formRow">
                      <h6>Ngo :</h6>
                      <select
                        
                        onChange={(e) => {
                          console.log({SELECTED:e.target.value});
                          setNgoId(e.target.value)}}
                        required
                        defaultValue={name}
                        className="selectg"
                      >
                        <option value="" disabled>
                          Please select Ngo
                        </option>
                        {ngos.data?.ngos?.rows.map((ngo) => (
                          <option key={ngo.user_id} value={ngo.user_id}>
                            {ngo.name}
                          </option>
                        ))}
                      </select>
                    </div>
      
                    <br></br>
                    <div className="row formRow">
                      <h6>Goal :</h6>
      
                      <input
                        type="number"
                        className="  inputt col-md-8 form-control cat"
                        placeholder="Enter the Goal"
                        required
                        value={goal}
                        onChange={(e) => {
                          setGoal(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <h6>Amount :</h6>
      
                      <input
                        type="number"
                        className="  inputt col-md-8 form-control cat"
                        placeholder="Enter the Amount"
                        required
                        value={amount}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                      />
                    </div>
                    <br />
      
                    <div className="row formRow">
                      <h6 className="col-md-3 textForm">Title :</h6>
      
                      <input
                        type="text"
                        className="  inputt col-md-8 form-control cat"
                        placeholder="Enter the Title"
                        value={title}
                        required
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
                    <div className="row formRow">
                      <h6>Description :</h6>
      
                      <input
                        type="text"
                        className="  inputt col-md-8 form-control cat"
                        placeholder="Enter the Description"
                        required
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
                    <div className="row formRow">
                      <h6>Start :</h6>
      
                      <input
                        type="date"
                        className="  inputt col-md-8 form-control cat"
                        required
                        value={timestart}
                        onChange={(e) => {
                          setTimeStart(e.target.value);
                        }}
                        max={timeend}
                      />
                    </div>
                    <br></br>
                    <div className="row formRow">
                      <h6>End :</h6>
      
                      <input
                        type="date"
                        className="  inputt col-md-8 form-control cat"
                        min={timestart}
                        value={timeend}
                        required
                        onChange={(e) => {
                          setTimeEnd(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
      
                    <div className="row formRow">
                      <label className="checkboxLabel">
                        <input
                          type="checkbox"
                          checked={ispublic === "Not Public" ? false : true}
                          onChange={(e)=>{
                            console.log({ispublic: ispublic})
                            setIsPublic((e.target.checked) === false ? "Not Public" : "Public")}}
                        />
                        <span className="checkboxText " style={{paddingLeft:"10px"}}>Public</span>
                      </label>

                      <label className="checkboxLabel">
                        <input
                          type="checkbox"
                          checked={is_approved === "Pending" ? false : true}
                          onChange={(e)=>{
                            setIsApproved((e.target.checked) === false ? "Pending" : "Approved")}}
                        />
                        <span className="checkboxText " style={{paddingLeft:"10px"}}>Approved</span>
                      </label>
                    </div>

                    <br></br>
                  </div>
              </form>
              <br></br>
            </div>

            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <button onClick={CloseEditModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() =>
                  EditDonationHandler()
                }
                className="buttoninmodal1"
              >
                Edit Challenge
              </button>
            </div>
          </Modal>

          {/* --------------------------------------- */}

          {/* DELETE */}
            <Modal
          isOpen={IsOpenDeleteModal}
          onRequestClose={CloseDeleteModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="delete modal"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              width: "40rem",
              height: "15rem",
              margin: "0 auto",
              padding: "10px",
              background: "white",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              zIndex: 1000,
            },
          }}
        >
          <div>
            <h2 className="modaltitle">Delete Challenge</h2>
            <h5>Are You Sure You Want To Delete This Challenge ?</h5>
          </div>
          <div
            style={{
              height: "4rem",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <button onClick={CloseDeleteModal} className="buttoninmodal">
              Close
            </button>
            <button
              onClick={() => DeleteDonationChallenge()}
              className="buttoninmodal1"
            >
              Delete
            </button>
          </div>
            </Modal>
          {/* ----------------------------------------------- */}



          <Header title="Give For a Cause "  buttonText="Add Challenge" onopen={OpenAddModal}/>
          
          <Box
              m="40px 0 0 0 "
              height="75vh"
              sx={{
              "& .MuiDataGrid-root": {
                  border: "none",
                  zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                  zIndex: 0,
              },
              "& .name-column--cell": {
                  color: colors.greenAccent[300],
                  zIndex: 0,
              },
              "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                  zIndex: 0,
              },
              "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                  zIndex: 0,
              },
              "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                  zIndex: 0,
              },
              "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                  zIndex: 0,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                  zIndex: 0,
              },
              }}
          >
              {loading && <Loading />}
              
              {!loading && records && (
              <DataGrid
                  rows={records}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  sx={{
                  "& .MuiDataGrid-cell": {
                      fontSize: "16px", // Adjust the font size as needed
                      color: "black",
                  },
                  "& .MuiDataGrid-columnHeader": {
                      fontSize: "16px",
                      color: "black",
                      fontWeight: "bold", // Adjust the font size as needed
                  },
                  }}
                  checkboxSelection={true}
              />
              )}
          </Box> 
        </Box>
  );
}

