import axios from "axios";
import { QuoteAction } from './../Quotes/QuotesReducers'

export const getQuote = () => async (dispatch) => {
    try {
        dispatch(QuoteAction.QuoteRequest());

        console.log("Here");

        const res = await axios.get(process.env.REACT_APP_API + "/quote/getall");

        dispatch(QuoteAction.QuoteSuccess(res));
    } catch (error) {
        dispatch(QuoteAction.QuoteFail(error));
    }
};



export const addQuote = (quote) => async (dispatch) => {
    dispatch(QuoteAction.QuoteRequest());
    try {
        await axios.post(process.env.REACT_APP_API + "/quote/createone", {
            quote,
        });
        dispatch(QuoteAction.addQuoteSuccess());
        dispatch(getQuote());
    } catch (e) {
        dispatch(QuoteAction.QuoteFail(e.response.data));
    }
};



export const deleteQuote = (id) => async (dispatch) => {
    dispatch(QuoteAction.QuoteRequest());
    try {
        await axios.delete(process.env.REACT_APP_API + "/quote/delete/" + id);
        dispatch(QuoteAction.deleteQuoteSuccess());
        dispatch(getQuote());
    } catch (e) {
        dispatch(QuoteAction.QuoteFail(e.response.data));
    }
};



export const updateQuote = (id, quote) => async (dispatch) => {
    dispatch(QuoteAction.QuoteRequest());
    try {
        await axios.put(process.env.REACT_APP_API + "/quote/update/" + id, {
            quote,
        });
        dispatch(QuoteAction.editQuoteSuccess());
        dispatch(getQuote());
    } catch (e) {
        dispatch(QuoteAction.QuoteFail(e.response.data));
    }
};

export const getcondition = () => async (dispatch) => {
    dispatch(QuoteAction.QuoteRequest());
    try {
        let url = process.env.REACT_APP_API + "/privacy/getall";
        const response = await axios.get(url);
        dispatch(QuoteAction.fetchSuccess1(response.data));
    } catch (e) {
        dispatch(QuoteAction.QuoteFail(e.response));
    }
};

export const addcondition = (Policy) => async (dispatch) => {
    dispatch(QuoteAction.QuoteRequest());
    try {
        await axios.post(process.env.REACT_APP_API + "/privacy/create", {
            Policy,
        });
        dispatch(QuoteAction.addconditionSuccess());
        dispatch(getcondition());
    } catch (e) {
        dispatch(QuoteAction.QuoteFail(e.response.data));
    }
};

export const deletecondition = (id) => async (dispatch) => {
    dispatch(QuoteAction.QuoteRequest());
    try {
        await axios.delete(process.env.REACT_APP_API + "/privacy/delete/" + id);
        dispatch(QuoteAction.deleteconditionSuccess());
        dispatch(getcondition());
    } catch (e) {
        dispatch(QuoteAction.QuoteFail(e.response.data));
    }
};

export const updatecondition = (id, Policy) => async (dispatch) => {
    dispatch(QuoteAction.QuoteRequest());
    try {
        await axios.put(process.env.REACT_APP_API + "/privacy/update/" + id, {
            Policy,
        });
        dispatch(QuoteAction.editconditionSuccess());
        dispatch(getcondition());
    } catch (e) {
        dispatch(QuoteAction.QuoteFail(e.response.data));
    }
};

export const clear = () => (dispatch) => {
    dispatch(QuoteAction.clearSuccess());
};
