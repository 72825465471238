import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    loading: false,
    donationChallenge: [],
    error: "",
  };
  
  const DonationChallengeSlice = createSlice({
    name: "Donation",
    initialState,
    reducers: {
      DonationChallengeRequest: (state, action) => {
        state.loading = true;
        state.error = null;
        state.donationChallenge = [];
      },
      DonationChallengeSuccess: (state, action) => {
        state.loading = false;
        state.error = null;
        state.donationChallenge = action.payload;
      },
  
      DonationChallengeFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.donationChallenge = [];
      },
      
    },
  });


  export const DonationChallengeActions = DonationChallengeSlice.actions;
export default DonationChallengeSlice;