import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./pages/Dashboard/Dashboard";
import Faq from "./pages/Faq/Faq";
import { useLocation } from "react-router-dom";


import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import District from "./scenes/District/District";

import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/Login/Login";
import Donations from "./pages/Donations/Donations";
import ContactUs from "./pages/ContactUs/ContactUs";
import Company from "./pages/Company/Company";
import Users from "./pages/Users/Users";
import DonationChallenge from "./pages/DonationChallenge/DonationChallenge";
import Reward from "./pages/Rewards/Reward";
import Quote from "./pages/Quotes/Quote";
import Notification from "./pages/Notification/Notification";
import Privacy from "./pages/Privacy/Privacy";
import Department from "./pages/Department/Department";
import Charity from "./pages/Charity/Charity";
import GiftChallenges from "./pages/GiftChallenge/giftChallenge";
import { CompanyEmployees } from "./pages/Company/CompanyEmployees";
import Cashout from "./pages/Cashout/Cashout";
import Ngo from "./pages/Ngo/Ngo";
import Market from "./pages/Market/Market";
import RewardsByChallenge from "./pages/GiftChallenge/rewardsByChallenge";
import ForgetPassword from "./pages/Login/ForgetPassword";
import NgoDonation from "./pages/NgoDonation/NgoDonation";
import NgoCompanies from "./pages/Ngo Pages/Ngo Companies/NgoCompanies";
import NgoCashouts from "./pages/Ngo Pages/Ngo Cashouts/NgoCashouts";
import NgoProfile from "./pages/Ngo Pages/NgoProfile/NgoProfile";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import Admin from "./pages/Admin/Admin";
import Company_profile from "./pages/CompanyPages/company_profile";



function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [closeSideBar, setCloseSideBar] = useState(false)
  const [role, setRole] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const role = localStorage.getItem('role');
    setRole(role);
    if(role === null){
      navigate('/login')
    }
  }, [localStorage.getItem('role')])




  const location = useLocation();

  // Example: Check if the current route is "/login" or "/forget"
  useEffect(() => {
    // Check if the current path contains "/login"
    const isDashboardRoute = location.pathname.includes('/login');
    const isDashboardRouteForget = location.pathname.includes('/forget');

    if (isDashboardRoute || isDashboardRouteForget) {
      setCloseSideBar(true);
    } else {
      setCloseSideBar(false);
    }
  }, [location.pathname]);




  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!closeSideBar && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {!closeSideBar && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forget" element={<ForgetPassword />} />
              {((role === 'Admin') || (role ==='SuperAdmin') )&& <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/district" element={<District />} />
                <Route path="/donations" element={<Donations />} />
                <Route path="faq" element={<Faq />} />
                <Route path="company" element={<Company />}></Route>
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/users" element={<Users />} />
                <Route path="/reward" element={<Reward />} />
                <Route path="/quote" element={<Quote />} />
                <Route path="/notification" element={<Notification />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/charity" element={<Charity />} />
                <Route path="/donationChallenge" element={<DonationChallenge />} />
                <Route path="/giftChallenges" element={<GiftChallenges />} />
                <Route path="/RewardsByChallenge/:challenge_id" element={<RewardsByChallenge />} />
                <Route path="/companyEmployees/:id" element={<CompanyEmployees />} />
                <Route path="/department" element={<Department />} />
                <Route path="/impact-categories" element={<Charity />} />
                <Route path="/cashout" element={<Cashout />} />
                <Route path="/ngo" element={<Ngo />} />
                {role === 'SuperAdmin' && <Route path="/admins" element={<Admin />} />}
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/market" element={<Market />} />
              </>}
              {role === 'Ngo' && <>
                <Route path="/ngoCompanies" element={<NgoCompanies />} />
                <Route path="/ngoDonation" element={<NgoDonation />} />
                <Route path="/ngoCashouts" element={<NgoCashouts />} />
                <Route path="/ngoProfile" element={<NgoProfile />} />
                <Route path="*" element={<NotFoundPage />} />
              </>}

              {role === 'Company' && <>
                <Route path="/" element={<Company_profile/>} />
                <Route path="*" element={<NotFoundPage />} />
              </>}


            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
