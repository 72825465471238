import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  District: [],
  addDistrict: [],
  updateDistrict: [],
  deleteDistrict: [],
};

const DistrictSlice = createSlice({
  name: "District",
  initialState,
  reducers: {
    DistrictRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    DistrictSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.District = action.payload;
    },

    DistrictFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addDistrictRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addDistrictSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addDistrict = action.payload;
    },

    addDistrictFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateDistrictRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateDistrictSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updateDistrict = action.payload;
    },

    updateDistrictFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteDistrictRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deleteDistrictSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteDistrict = action.payload;
    },

    deleteDistrictFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const DistrictAction = DistrictSlice.actions;
export default DistrictSlice;
