import axios from "axios";
import { FaqAction } from "./FaqReducers";

export const getFaq = () => async (dispatch) => {
  try {
    dispatch(FaqAction.FaqRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/FAQS/getAll");

    dispatch(FaqAction.FaqSuccess(res));
  } catch (error) {
    dispatch(FaqAction.FaqFail(error));
  }
};
export const AddFaq = (question, answer) => async (dispatch) => {
  dispatch(FaqAction.addFaqRequest());
  const token = localStorage.getItem("jwtToken");
  try { 
    await axios.post(
      process.env.REACT_APP_API + "/FAQS/create",
      {
        question,
        answer,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(FaqAction.addFaqSuccess());
    dispatch(getFaq());
  } catch (e) {
    dispatch(FaqAction.addFaqFail(e.response.data));
  }
};

export const updateFaq = (id, question, answer) => async (dispatch) => {
  dispatch(FaqAction.updateFaqRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.put(
      process.env.REACT_APP_API + "/FAQS/update/" + id,
      {
        question,
        answer,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(FaqAction.updateFaqSuccess());
    dispatch(getFaq());
  } catch (e) {
    dispatch(FaqAction.updateFaqFail(e.response.data));
  }
};
export const deleteFaq = (id) => async (dispatch) => {
  dispatch(FaqAction.deleteFaqRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    //id?
    await axios.delete(process.env.REACT_APP_API + "/FAQS/delete/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(FaqAction.deleteFaqSuccess());
    dispatch(getFaq());
  } catch (e) {
    dispatch(FaqAction.deleteFaqFail(e.response.data));
  }
};
