import { combineReducers } from "redux";
import DistrictSlice from "./District/DistrictReducers";
import FaqSlice from "./Faq/FaqReducers";
import ContactSlice from "./Contactus/ContactReducers";
import CompanySlice from "./Company/CompanyReducer";
import authSlice from "./Auth/AuthReducers";
import DonationSlice from "./Donations/DonationsReducer";
import DepartmentSlice from "./Department/DepartmentReducers";
import NgoSlice from "./Ngo/NgoReducer";
import DonationChallengeSlice from "./DonationChallenge/DonationChallengeReducer";
import RewardsSlice from "./Reward/RewardReducers";
import QuoteSlice from "./Quotes/QuotesReducers";
import NotificationSlice from "./Notification/NotificationReducer";
import PrivacySlice from "./Privacy/PrivacyReducers";
import UsersSlice from "./Users/UsersReducers";
import CharitySlice from "./Charity/CharityReducers";
import giftChallengeSlice from "./giftChallenge/giftChallengeReducer";
import CashoutSlice from "./Cashout/CashoutReducers";
import StatisticsSlice from "./Statistics/StatisticsReducer";
import MarketSlice from "./Market/MarketReducers";
import NgoDonationSlice from "./NgoDonation/NgoDonationReducers";
import AdminSlice from "./Admin/AdminReducers";


const RootReducer = combineReducers({
  District: DistrictSlice.reducer,
  GiftChallenge: giftChallengeSlice.reducer,
  Faq: FaqSlice.reducer,
  ContactUs: ContactSlice.reducer,
  Company: CompanySlice.reducer,
  Auth: authSlice.reducer,
  Donation: DonationSlice.reducer,
  Departments: DepartmentSlice.reducer,
  DonationChallenge: DonationChallengeSlice.reducer,
  Ngo: NgoSlice.reducer,
  Rewards: RewardsSlice.reducer,
  Quote: QuoteSlice.reducer,
  Notification: NotificationSlice.reducer,
  Privacy: PrivacySlice.reducer,
  Users: UsersSlice.reducer,
  Department: DepartmentSlice.reducer,
  Charity: CharitySlice.reducer,
  Cashout: CashoutSlice.reducer,
  Statistics: StatisticsSlice.reducer,
  Market: MarketSlice.reducer,
  NgoDonation: NgoDonationSlice.reducer,
  Admin: AdminSlice.reducer,
});

export default RootReducer;