import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import "./../../scenes/Style.css";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import {
  addNotification,
  deleteNotification,
  getNotification,
} from "../../Redux/Notification/NotificationActions";
import isAuth from "./../../Utils/isAuth";

const Notification = () => {
  const dispatch = useDispatch();
  const { Notification, loading } = useSelector((state) => state.Notification);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getNotification());
  }, [dispatch]);

  const [data, setData] = useState();

  useEffect(() => {
    if (Notification) {
      setData(
        Notification?.notification?.map((data, id) => {
          return {
            id: id,
            type: data.type,
            message: data.message,
            createdAt: data.createdAt,
          };
        })
      );
    }
  }, [Notification]);

  console.log(data);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");

  const deleteplanhandle = (id) => {
    dispatch(deleteNotification(id));
    setModalIsOpen(false);
    setId("");
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [addmodalIsOpen, setaddModalIsOpen] = useState(false);

  const addopenModal = () => {
    setaddModalIsOpen(true);
  };

  const addcloseModal = () => {
    setaddModalIsOpen(false);
    setType("");
    setMessage("");
  };
  const addplanhandle = (type, message) => {
    dispatch(addNotification(type, message));
    setaddModalIsOpen(false);
    setType("");
    setMessage("");
  };

  const columns = [
    { field: "type", headerName: "Type", flex: 1 },
    { field: "message", headerName: "Message", flex: 1 },
    {
      field: "createdAt",
      headerName: "createdAt",
      flex: 1,
      renderCell: (record) => {
        const formatedDate = new Date(record?.row?.createdAt).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
        return <span>{formatedDate}</span>;
      },
    },
  ];

  return (
    <Box m="20px">
      <Modal
        isOpen={addmodalIsOpen}
        onRequestClose={addcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Add modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "20rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Notification</h2>
          <form>
            <div className="modal-body modalview">
              <Box sx={{ gap: "2rem" }} className="row formRow inputstyle">
                <Box>
                  <h6>Type:</h6>
                  <input
                    type="text"
                    className="  inputt col-md-8 form-control cat"
                    value={type}
                    placeholder="Enter the Type"
                    required
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  />{" "}
                </Box>
                <Box>
                  <h6>Message:</h6>
                  <input
                    type="text"
                    className="  inputt col-md-8 form-control cat"
                    value={message}
                    placeholder="Enter the Notification"
                    required
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </Box>
              </Box>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={addcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => addplanhandle(type, message)}
            className="buttoninmodal1"
          >
            Add Notification
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="delete modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40rem",
            height: "15rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Delete Notification</h2>
          <h5>Are You Sure You Want To Delete This Notification ?</h5>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <button onClick={closeModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => deleteplanhandle(id)}
            className="buttoninmodal1"
          >
            Delete
          </button>
        </div>
      </Modal>
      <Header
        title="Notifications"
        buttonText="Add Notification"
        onopen={addopenModal}
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && data && (
          <DataGrid
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            getRowId={(row) => row.id}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: "16px", // Adjust the font size as needed
                color: "black",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold", // Adjust the font size as needed
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default Notification;
