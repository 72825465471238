import React , {useEffect,useState} from 'react';
import {useSelector,useDispatch} from 'react-redux';



const NgoCompanies = () => {
    return (
        <>
        NGO COMPANIES
        </>
    )
}

export default NgoCompanies;