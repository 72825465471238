import { Box } from '@mui/material';
import React from 'react';
import Modal from "react-modal";
import { useState ,useEffect,Fragment} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { getFaq,AddFaq,deleteFaq,updateFaq } from '../../Redux/Faq/FaqActions';
import { tokens } from "../../theme"
import { useTheme } from "@mui/material";
import Loading from '../../components/Loading';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from '../../components/Header';
import { mockDataFaq } from '../../data/mockData';
import isAuth from '../../Utils/isAuth';
import { useNavigate } from 'react-router-dom';


export default function Faq(){

    const dispatch = useDispatch();
    const [editmodalIsOpen, setEditModalIsOpen] = useState(false);
    const [id, setId] = useState("");
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("") 
    const [data, setData] = useState(mockDataFaq);
    const navigate = useNavigate();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { Faq, loading } = useSelector((state) => state.Faq);
    
    useEffect(() => {
      if (!isAuth(dispatch)) navigate("/login");
    }, [dispatch, navigate]);


    useEffect(() => {
      dispatch(getFaq());
    }, [dispatch]);

    const [records, setRecords] = useState([]);
    
    useEffect(() => {
      if (Faq?.data?.faqs?.length > 0) {
        console.log({Faq:Faq})
        setRecords(Faq?.data?.faqs)
      }
      else{
        console.log({Faq:Faq})
        setRecords([])
      }
    }, [Faq]);


    const openEditModal = () => {
        setEditModalIsOpen(true);
    }

    const closeEditModal = () => {
        setEditModalIsOpen(false);
        setQuestion("");
        setAnswer("");
    };


    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const openDeleteModal = () => {
        setDeleteModalIsOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalIsOpen(false);
    };
  
 
    const addPlanHandle = (question, answer) => {
        console.log({question, answer});
      // if inputs are empty return false ; 
      if (question.trim() === '' || answer.trim() === '') {
        // Display an error message, prevent form submission, or handle the empty input case.
        console.error('Question and Answer cannot be empty.');
        return;
      }

        dispatch(AddFaq(question, answer));
        setAddModalIsOpen(false);

        setQuestion("");
        setAnswer("");
      };

    const editPlanHandle = (question, answer) => {
      if (question.trim() === '' || answer.trim() === '') {
        // Display an error message, prevent form submission, or handle the empty input case.
        console.error('Question and Answer cannot be empty.');
        return;
      }
        dispatch(updateFaq(id, question, answer));
        setEditModalIsOpen(false);
        setQuestion("");
        setAnswer("");
    };

    const deletePlanHandle = (id) => {
        dispatch(deleteFaq(id));
        setDeleteModalIsOpen(false);
        setId("");
    };

    


    const [addmodalIsOpen, setAddModalIsOpen] = useState(false);

    const addopenModal = () => {
        setAddModalIsOpen(true);
    };

    const addcloseModal = () => {
        setAddModalIsOpen(false);
        setQuestion("");
        setAnswer("");
    };


    const columns = [
        { field: "question", headerName: "Question",flex:1 },
        {
          field: "answer",
          headerName: "Answer ",
          flex:2,
        },
    
        {
          field: "action",
          headerName: "Action",
          flex: 1,
          width: 100,
          renderCell: (record) => {
            return (
              <Fragment>
                <button
                  data-toggle="modal"
                  data-target="#update-carousel-modal"
                  className="actions"
                  style={{ marginRight: "5px" }}
                  onClick={() => {
                    setId(record.row.id);
                    setQuestion(record.row.question);
                    setAnswer(record.row.answer);
                    openEditModal();
                  }}
                >
                  Edit
                </button>
                <button
                  data-toggle="modal"
                  data-target="#delete-carousel-modal"
                  className="actions1"
                  onClick={() => {
                    setId(record.row.id);
                    openDeleteModal();
                  }}
                >
                  Delete
                </button>
              </Fragment>
            );
          },
        },
      ];
  
    

    return(
        <Box m="20px">
        <Modal
        isOpen={addmodalIsOpen}
        onRequestClose={addcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "20rem",

            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Faq</h2>
          <form >
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Question:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={question}
                  placeholder="Enter the question"
                  required
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                />
              </div>

              <div className="row formRow inputstyle">
                <h6>Answer:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={answer}
                  placeholder="Enter the  answer"
                  required
                  onChange={(e) => {
                    setAnswer(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>

        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={addcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => addPlanHandle( question, answer)}
            className="buttoninmodal1"
          >
            Add Faq
          </button>
        </div>
        </Modal>

        {/* Edit MODAL  */}
        <Modal
            isOpen={editmodalIsOpen}
            onRequestClose={closeEditModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Add modal"
            style={{
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
                width: "40%",
                height: "20rem",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
            },
            }}
        >
            <div>
            <h2 className="modaltitle">Edit Faq</h2>
            <form>
                <div className="modal-body modalview">
                <div className="row formRow inputstyle">
                    <h6>Question:</h6>
                    <input
                    type="text"
                    className="  inputt col-md-8 form-control cat"
                    value={question}
                    placeholder="Enter the question"
                    required
                    onChange={(e) => {
                        setQuestion(e.target.value);
                    }}
                    />
                </div>

                <div className="row formRow inputstyle">
                    <h6>Answer:</h6>
                    <input
                    type="text"
                    className="  inputt col-md-8 form-control cat"
                    value={answer}
                    placeholder="Enter the  answer"
                    required
                    onChange={(e) => {
                        setAnswer(e.target.value);
                    }}
                    />
                </div>
                </div>
            </form>
            </div>
            <div
            style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
            }}
            >
            <button onClick={closeEditModal} className="buttoninmodal">
                Close
            </button>
            <button
                onClick={() => editPlanHandle(question, answer)}
                className="buttoninmodal1"
            >
                Edit Faq
            </button>
            </div>
        </Modal>
          
          {/* Delete MODAL  */}
        <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={closeDeleteModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="delete modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40rem",
            height: "15rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Delete Faq</h2>
          <h5>Are You Sure You Want To Delete This Faq ?</h5>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <button onClick={closeDeleteModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => deletePlanHandle(id)}
            className="buttoninmodal1"
          >
            Delete
          </button>
        </div>
        </Modal>

        <Header title="Faq" buttonText="Add Faq" onopen={addopenModal} />
     
        <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
            "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
            },
            "& .MuiDataGrid-cell": {
                borderBottom: "none",
                zIndex: 0,
            },
            "& .name-column--cell": {
                color: colors.greenAccent[300],
                zIndex: 0,
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                zIndex: 0,
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                zIndex: 0,
            },
            "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
                zIndex: 0,
            },
            "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
                zIndex: 0,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
                zIndex: 0,
            },
            }}
        >
            {loading && <Loading />}
            {!loading && records && (
            <DataGrid
                rows={records}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                sx={{
                "& .MuiDataGrid-cell": {
                    fontSize: "16px", // Adjust the font size as needed
                    color: "black",
                },
                "& .MuiDataGrid-columnHeader": {
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold", // Adjust the font size as needed
                },
                }}
                checkboxSelection={true}
            />
            )}
        </Box>
        </Box>
    )
}

