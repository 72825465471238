import { Box } from "@mui/material";
import React from "react";
import Modal from "react-modal";
import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getfaq, AddFaq, deletefaq } from "../../Redux/Faq/FaqActions";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Loading from "../../components/Loading";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { mockDataContactUs } from "../../data/mockData";
import { getcontactus } from "../../Redux/Contactus/ContactActions";
import isAuth from "../../Utils/isAuth";
import { useNavigate } from "react-router-dom";

export default function ContactUs() {
  const dispatch = useDispatch();
  const [editmodalIsOpen, setEditModalIsOpen] = useState(false);
  const [id, setId] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [data, setData] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { contactus, loading } = useSelector((state) => state.ContactUs);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getcontactus());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getcontactus());
  }, [dispatch]);

  useEffect(() => {
    if (contactus) {
      setData(contactus);
    } else {
      console.log("FAILED");
    }
  }, [contactus]);
  useEffect(() => {
    setData(contactus.contacts);
  }, [contactus]);

  console.log(data);
  const columns = [
    { field: "type", headerName: "Type", width: 200 },
    {
      field: "email",
      headerName: "Email",
      width: 400,
      renderCell: (record) => {
        return (
          <a href={`mailto:${record?.row?.user?.email}`}>
            {record?.row?.user?.email}
          </a>
        );
      },
    },

    {
      field: "message",
      headerName: "Messages ",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      renderCell: (record) => {
        const formatedDate = new Date(
          record?.row?.user?.createdAt
        ).toLocaleString("en-US", { timeZone: "UTC" });
        return <span>{formatedDate}</span>;
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="CONTACT US " show={true} />
      <Box
        m="40px 0 0 0 "
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            zIndex: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            zIndex: 0,
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            zIndex: 0,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            zIndex: 0,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            zIndex: 0,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            zIndex: 0,
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
            zIndex: 0,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
            zIndex: 0,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && data && (
          <DataGrid
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: "16px", // Adjust the font size as needed
                color: "black",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold", // Adjust the font size as needed
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
}
