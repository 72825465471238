import { createSlice } from "@reduxjs/toolkit";



export const initialState = {
    loading: false,
    companies: [],
    companyEmployees: [],
    companyProfile:{},
    error: "",
   
  };


  const CompanySlice = createSlice({
    name: "Company",
    initialState,
    reducers: {
      CompanyRequest: (state, action) => {
        state.loading = true;
        state.error = null;
      },
      CompanySuccess: (state, action) => {
        state.loading = false;
        state.error = null;
        state.companies = action.payload;
      },

      CompanyEmployeeSuccess: (state, action) => {
        state.loading = false ; 
        state.error = null ; 
        state.companyEmployees = action.payload; 
      }, 

      CompanyProfileSuccess: (state, action) => {
        state.loading = false ; 
        state.error = null ; 
        state.companyProfile = action.payload;
      },
      CompanyFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.companies = action.payload;
      },

    },
  });
  
  export const CompanyAction = CompanySlice.actions;
  export default CompanySlice;