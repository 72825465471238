import axios from "axios";
import { CompanyAction } from "./CompanyReducer";


export const getCompany = () => async (dispatch) => {
  dispatch(CompanyAction.CompanyRequest());
  try {
    let url = process.env.REACT_APP_API + "/company/getcompanies?limit=10&page=1";
    const response = await axios.get(url);
    dispatch(CompanyAction.CompanySuccess(response.data));
  } catch (e) {
    dispatch(CompanyAction.CompanyFail(e.response));
  }
};

export const getCompanyEmployee = (id) => async (dispatch) => {
    dispatch(CompanyAction.CompanyRequest());
    try {
      let url = process.env.REACT_APP_API + "/company/getcompany/" + id;
      const response = await axios.get(url);
      dispatch(CompanyAction.CompanyEmployeeSuccess(response.data));
    } catch (e) {
      dispatch(CompanyAction.CompanyFail(e.response));
    }
};

export const addCompany =
  (email, password, username, image, department_id, country, location) =>
  async (dispatch) => {
    console.log({IMAGE:image})
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("username", username);
    formData.append("image", image);
    for (const department of department_id) {
      formData.append("department_id", department);
    }
    formData.append("country", country);
    formData.append("location", location);

    dispatch(CompanyAction.CompanyRequest());
    try {
      await axios.post(process.env.REACT_APP_API + "/company/create", formData);
      dispatch(CompanyAction.CompanySuccess());
      dispatch(getCompany());
    } catch (e) {
      dispatch(CompanyAction.CompanyFail(e.response.data));
    }
};

export const updateCompany =
  (
    userId,
    company_code,
    email,
    image,
    username,) =>
  async (dispatch) => {
    

    const formData = new FormData();
    formData.append("company_code", company_code)
    formData.append("email", email);
    formData.append("image", image);
    formData.append("username", username);

    dispatch(CompanyAction.CompanyRequest());
    try {
      await axios.put(
        process.env.REACT_APP_API + "/company/edit/" + userId,
        formData
      );
      dispatch(CompanyAction.CompanySuccess());
      dispatch(getCompany());
    } catch (e) {

      dispatch(CompanyAction.CompanyFail(e.message));
    }
  };


export const deleteCompany = (id) => async (dispatch) => {
  console.log("delete company",id)
dispatch(CompanyAction.CompanyRequest());
try {
    await axios.put(process.env.REACT_APP_API + "/company/deleteCompany/" + id);
    dispatch(CompanyAction.CompanySuccess());
    dispatch(getCompany());
} catch (e) {
    dispatch(CompanyAction.CompanyFail(e.response.data));
}
};

export const getCompanyByToken = (token) =>async (dispatch) => {
  dispatch(CompanyAction.CompanyRequest());
  try{
    const response = await axios.get(process.env.REACT_APP_API + "/company/getCompanybytoken",{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    
    });

    console.log({RESPONSE:response.data})
    dispatch(CompanyAction.CompanyProfileSuccess(response.data));
  }catch(err){
    dispatch(CompanyAction.CompanyFail(err.response.data));
  }
}