import axios from "axios";
import { CashoutActions } from "./CashoutReducers";

export const getCashout = () => async (dispatch) => {
    dispatch(CashoutActions.CashoutRequest());
    try {
        let url = process.env.REACT_APP_API + "/Cashout/getallcashouts";
        const response = await axios.get(url);
        dispatch(CashoutActions.CashoutSuccess(response.data));
    } catch (e) {
        dispatch(CashoutActions.CashoutFail(e.response));
    }
};

export const Approvec = (id) => async (dispatch) => {

    dispatch(CashoutActions.CashoutRequest());

    try {
        let url = process.env.REACT_APP_API + "/admin/paidCashout/" + id;
        const response = await axios.put(url);
        dispatch(CashoutActions.approvedCashoutSuccess(response.data));
        dispatch(getCashout());
    } catch (e) {
        dispatch(CashoutActions.CashoutFail(e.response));
    }
};