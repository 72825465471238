import axios from "axios";
import { NgoActions } from "./NgoReducer";



export const getNgo = () => async (dispatch) => {
  dispatch(NgoActions.NgoRequest())
  try {
    let url = process.env.REACT_APP_API + "/NGO/getNGOs";
    const response = await axios.get(url);
    dispatch(NgoActions.NgoSuccess(response))
  } catch (e) {
    dispatch(NgoActions.NgoFail(e.message()));
  }
};

export const addNgo =
  (
    images,
    email,
    password,
    url,
    description,
    name,
    charity_ids,
    logo,
    location
  ) =>
    async (dispatch) => {
      const formData = new FormData();
      for (const image of images) {
        formData.append("image", image);
      }
      for (const charity_id of charity_ids) {
        formData.append("charity_ids", charity_id);
      }

      formData.append("email", email);
      formData.append("password", password);
      formData.append("url", url);

      formData.append("description", description);
      formData.append("name", name);

      formData.append("logo", logo);
      formData.append("location", location);

      dispatch(NgoActions.NgoRequest())
      try {
        await axios.post(process.env.REACT_APP_API + "/NGO/createNGO", formData);
        dispatch(NgoActions.addNgoSuccess());
        dispatch(getNgo());
      } catch (e) {
        dispatch(NgoActions.NgoFail(e.response.data));
      }
    };

export const deleteNgo = (id) => async (dispatch) => {
  dispatch(NgoActions.NgoRequest())
  try {
    const url = await axios.put(
      process.env.REACT_APP_API + "/NGO/removeNgo/" + id
    );

    dispatch(NgoActions.deleteNgoSuccess());
    dispatch(getNgo());
  } catch (e) {
    dispatch(NgoActions.NgoFail(e.response.data));
  }
};

export const updateNgo =
  (id, url, description, name, location) => async (dispatch) => {
    dispatch(NgoActions.NgoRequest())
    try {
      await axios.put(process.env.REACT_APP_API + "/NGO/updateNGO/" + id, {
        url,
        description,
        name,
        location,
      });
      dispatch(NgoActions.editNgoSuccess());
      dispatch(getNgo());
    } catch (e) {
      dispatch(NgoActions.NgoFail(e.response.data));
    }
  };

