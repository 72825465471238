import axios from "axios";
import { UsersActions } from "./UsersReducers";

export const getUsers = () => async (dispatch) => {
    dispatch(UsersActions.fetchRequest());
    try {
        let url = process.env.REACT_APP_API + "/user/getall/User?limit=100";
        const response = await axios.get(url);
        dispatch(UsersActions.fetchSuccess(response.data));
    } catch (e) {
        dispatch(UsersActions.fetchFail(e.response));
    }
};

export const deleteUser = (id) => async (dispatch) => {
    dispatch(UsersActions.fetchRequest());
    try {
        await axios.delete(process.env.REACT_APP_API + "/user/" + id);
        dispatch(UsersActions.deleteUserSuccess());
        dispatch(getUsers());
    } catch (e) {
        dispatch(UsersActions.fetchFail(e.response.data));
    }
};

export const clear = () => (dispatch) => {
    dispatch(UsersActions.clearSuccess());
};