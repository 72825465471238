import axios from "axios";
import {StatisticsActions} from "./StatisticsReducer";

export const getStatistics = () => async (dispatch) => {
    dispatch(StatisticsActions.fetchRequest());
    try{
        const response = await axios.get(process.env.REACT_APP_API + "/admin/getStatistics")
        var array = []
        var data = response.data.data
        for (const key in data) {
            array.push({key:key,value:data[key]})
          }
        dispatch(StatisticsActions.fetchSuccess(array))
    }catch(err){
        dispatch(StatisticsActions.fetchFail(err));
    }
}