import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SecurityIcon from "@mui/icons-material/Security";
import GroupIcon from "@mui/icons-material/Group";
import BusinessIcon from "@mui/icons-material/Business";
import CategoryIcon from "@mui/icons-material/Category";
import AllInclusiveRoundedIcon from "@mui/icons-material/AllInclusiveRounded";
import AddCardIcon from "@mui/icons-material/AddCard";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CardGiftcardSharpIcon from "@mui/icons-material/CardGiftcardSharp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import "./../../scenes/Style.css";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const AdminSideBar = (isCollapsed) => {
    return (
      <>
        {!isCollapsed && (
          <Box mb="25px">
            <Box textAlign="center">
              <Typography
                variant="h4"
                color={colors.grey[100]}
                flexGrow={1}
                fontWeight="bold"
                sx={{ m: "10px 0 0 0" }}
              >
                {localStorage.getItem("email")}
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                {localStorage.getItem("role")}
              </Typography>
            </Box>
          </Box>
        )}

        <Box paddingLeft={isCollapsed ? undefined : "10%"}>
          <Item
            title="Dashboard"
            to="/"
            icon={<HomeOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Contact Us"
            to="/contactus"
            icon={<ContactPhoneIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <Item
            title="Give for a Cause"
            to="/donationChallenge"
            icon={<AllInclusiveRoundedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Faq"
            to="/faq"
            icon={<LiveHelpIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Donations"
            to="/donations"
            icon={<AttachMoneyIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Gift Challenges"
            to="/giftChallenges"
            icon={<CardGiftcardSharpIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Companies"
            to="/company"
            icon={<BusinessIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Users"
            to="/users"
            icon={<GroupIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Rewards"
            to="/reward"
            icon={<CardGiftcardIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Quote"
            to="/quote"
            icon={<FormatQuoteIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Notifications"
            to="/notification"
            icon={<NotificationsNoneIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Privacy"
            to="/privacy"
            icon={<SecurityIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Department"
            to="/department"
            icon={<ApartmentIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Impact Categories"
            to="/impact-categories"
            icon={<CategoryIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Cashout"
            to="/cashout"
            icon={<AddCardIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Ngo"
            to="/ngo"
            icon={<CheckCircleOutlineIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Market"
            to="/market"
            icon={<StorefrontIcon />}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
      </>
    );
  };



  const SuperAdminSideBar = (isCollapsed) => {
    return (
      <>
        {!isCollapsed && (
          <Box mb="25px">
            <Box textAlign="center">
              <Typography
                variant="h4"
                color={colors.grey[100]}
                flexGrow={1}
                fontWeight="bold"
                sx={{ m: "10px 0 0 0" }}
              >
                {localStorage.getItem("email")}
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                {localStorage.getItem("role")}
              </Typography>
            </Box>
          </Box>
        )}

        <Box paddingLeft={isCollapsed ? undefined : "10%"}>
          <Item
            title="Dashboard"
            to="/"
            icon={<HomeOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Contact Us"
            to="/contactus"
            icon={<ContactPhoneIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <Item
            title="Admins"
            to="/admins"
            icon={<SupervisorAccountIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <Item
            title="Give for a Cause"
            to="/donationChallenge"
            icon={<AllInclusiveRoundedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Faq"
            to="/faq"
            icon={<LiveHelpIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Donations"
            to="/donations"
            icon={<AttachMoneyIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Gift Challenges"
            to="/giftChallenges"
            icon={<CardGiftcardSharpIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Companies"
            to="/company"
            icon={<BusinessIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Users"
            to="/users"
            icon={<GroupIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Rewards"
            to="/reward"
            icon={<CardGiftcardIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Quote"
            to="/quote"
            icon={<FormatQuoteIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Notifications"
            to="/notification"
            icon={<NotificationsNoneIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Privacy"
            to="/privacy"
            icon={<SecurityIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Department"
            to="/department"
            icon={<ApartmentIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Category"
            to="/impact-categories"
            icon={<CategoryIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Cashout"
            to="/cashout"
            icon={<AddCardIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Ngo"
            to="/ngo"
            icon={<CheckCircleOutlineIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Market"
            to="/market"
            icon={<StorefrontIcon />}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
      </>
    );
  };

  const NgoSideBar = (isCollapsed) => {
    return (
      <>
        {!isCollapsed && (
          <Box mb="25px">
            <Box textAlign="center">
              <Typography
                variant="h4"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px 0 0 0" }}
              >
                {localStorage.getItem("email")}
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                {localStorage.getItem("role")}
              </Typography>
            </Box>
          </Box>
        )}

        <Box paddingLeft={isCollapsed ? undefined : "10%"}>
          <Item
            title="NGO Dashboard"
            to="/"
            icon={<DashboardIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="NGO Profile"
            to="/ngoProfile"
            icon={<AccountBoxIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="NGO Cashouts"
            to="/ngoCashouts"
            icon={<AddCardIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title="Donations (Cashout)"
            to="/ngoDonation"
            icon={<MailOutlineIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <Item
            title="Companies"
            to="/ngoCompanies"
            icon={<BusinessIcon />}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
      </>
    );
  };

  const CompaniesSideBar = (isCollapsed) => {
    return (
      <>
        {!isCollapsed && (
          <Box mb="25px">
            <Box textAlign="center">
              <Typography
                variant="h4"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px 0 0 0" }}
              >
                Test
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                Admin
              </Typography>
            </Box>
          </Box>
        )}

        <Box paddingLeft={isCollapsed ? undefined : "10%"}>
          <Item
            title="Companies Dashboard"
            to="/"
            icon={<HomeOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
      </>
    );
  };

  const location = useLocation();

  return (
    <Box
      className="custom-box"
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          ...(location.pathname.toString() !== "/" ||
          location.pathname.toString() !== "/ngoDonation"
            ? {}
            : { height: "auto" }),
          zIndex: "0",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
          zIndex: "0",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {localStorage.getItem("role") === "Admin" &&
            AdminSideBar(isCollapsed)}
          {localStorage.getItem("role") === "Company" &&
            CompaniesSideBar(isCollapsed)}
          {localStorage.getItem("role") === "SuperAdmin" &&
            SuperAdminSideBar(isCollapsed)}
          {localStorage.getItem("role") === "Ngo" && NgoSideBar(isCollapsed)}
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
