import React , {useEffect,useState} from 'react';
import {useSelector,useDispatch} from 'react-redux';



const NgoProfile = () => {
    return (
        <>
        NGO Profile
        </>
    )
}

export default NgoProfile;