import axios from "axios";
import { PrivacyActions } from "./PrivacyReducers";

export const getcondition = () => async (dispatch) => {
    dispatch(PrivacyActions.fetchRequest());

    try {
        let url = process.env.REACT_APP_API + "/privacy/getall";
        const response = await axios.get(url);
        dispatch(PrivacyActions.fetchSuccess(response.data));
    } catch (e) {
        dispatch(PrivacyActions.fetchFail(e.response));
    }
};

export const addcondition = (Policy) => async (dispatch) => {
    dispatch(PrivacyActions.fetchRequest());
    try {
        await axios.post(process.env.REACT_APP_API + "/privacy/create", {
            Policy,
        });
        dispatch(PrivacyActions.addconditionSuccess());
        dispatch(getcondition());
    } catch (e) {
        dispatch(PrivacyActions.fetchFail(e.response.data));
    }
};

export const deletecondition = (id) => async (dispatch) => {
    dispatch(PrivacyActions.fetchRequest());
    try {
        await axios.delete(process.env.REACT_APP_API + "/privacy/delete/" + id);
        dispatch(PrivacyActions.deleteconditionSuccess());
        dispatch(getcondition());
    } catch (e) {
        dispatch(PrivacyActions.fetchFail(e.response.data));
    }
};

export const updatecondition = (id, Policy) => async (dispatch) => {
    dispatch(PrivacyActions.fetchRequest());
    try {
        await axios.put(process.env.REACT_APP_API + "/privacy/update/" + id, {
            Policy,
        });
        dispatch(PrivacyActions.editconditionSuccess());
        dispatch(getcondition());
    } catch (e) {
        dispatch(PrivacyActions.fetchFail(e.response.data));
    }
};

export const clear = () => (dispatch) => {
    dispatch(PrivacyActions.clearSuccess());
};
