import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: false,
    loading: false,
    error: "",
    responeData: null
}


const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        authRequest: (state, action) => {
            state.loading = true;
            state.error = "";
        },
        authLoginSuccess: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isAuthenticated = true;
        }
        , authFail: (state, action) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.error = action.payload;
        },
        authForgetPassword: (state, action) => {
            state.loading = false;
            state.responeData = action.payload
        },
        authLogout: (state, action) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.error = "";
        }
    }
})

export const AuthenticationActions = authSlice.actions;
export default authSlice;