
import axios from 'axios';
import { NotificationAction } from './NotificationReducer'

export const getNotification = () => async (dispatch) => {
    dispatch(NotificationAction.NotificationRequest());
    try {
        let url = process.env.REACT_APP_API + "/Notification/getallnotification";
        const response = await axios.get(url);
        console.log(response.data);
        dispatch(NotificationAction.NotificationSuccess(response.data));
    } catch (e) {
        dispatch(NotificationAction.NotificationFail(e.response));
    }
};


export const addNotification = (type, message) => async (dispatch) => {
    dispatch(NotificationAction.NotificationRequest());
    try {
        await axios.post(process.env.REACT_APP_API + "/Notification/create", {
            type,
            message,
        });
        dispatch(NotificationAction.addNotificationSuccess());
        dispatch(getNotification());
    } catch (e) {
        dispatch(NotificationAction.NotificationFail(e.response.data));
    }
};


export const deleteNotification = (id) => async (dispatch) => {
    dispatch(NotificationAction.NotificationRequest());
    try {
        await axios.delete(process.env.REACT_APP_API + "/notification/delete/" + id);
        dispatch(NotificationAction.deleteNotificationSuccess());
        dispatch(getNotification());
    } catch (e) {
        dispatch(NotificationAction.NotificationFail(e.response.data));
    }
};



export const updateNotification = (id, type, message) => async (dispatch) => {
    dispatch(NotificationAction.NotificationRequest());
    try {
        await axios.put(process.env.REACT_APP_API + "/notification/update/" + id, {
            type,
            message,
        });
        dispatch(NotificationAction.editNotificationSuccess());
        dispatch(getNotification());
    } catch (e) {
        dispatch(NotificationAction.NotificationFail(e.response.data));
    }
};
