import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../../index.css";
import { getUsers } from "../../Redux/Users/UsersActions";
import { useNavigate } from "react-router-dom";
import isAuth from "../../Utils/isAuth";

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Users, loading } = useSelector((state) => state.Users);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    if (Users) {
      setData(Users?.users?.rows);
    }
  }, [Users]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    {
      field: "avatar",
      headerName: "Profile",
      renderCell: (record) => {
        if (record.row.userprofile.avatar) {
          return (
            <Fragment>
              <img
                style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
                src={record.row.userprofile.avatar}
                alt=""
              />
            </Fragment>
          );
        } else {
          return <img src="/imageNotFound.jpg" width={60} height={60} alt="" />;
        }
      },
    },
    {
      field: "username",
      headerName: "Username",
      renderCell: (record) => {
        return <>{record.row.userprofile.username}</>;
      },
    },

    { field: "email", headerName: "Email", width: 300 },
    {
      field: "steps",
      headerName: "Steps",
      renderCell: (record) => {
        return <>{record.row.userprofile.steps}</>;
      },
    },
    { field: "phone", headerName: "Phone" },
    { field: "role", headerName: "Role" },

    {
      field: "wallet",
      headerName: "Wallet",
      valueGetter: (params) => String(params.row.userprofile.wallet),
      renderCell: (record) => {
        const walletValue = record.row.userprofile.wallet;
        const formattedWallet = Number(walletValue).toFixed(2);
        return <>{formattedWallet}</>;
      },
    },
    {
      field: "streak",
      headerName: "Streak",
      valueGetter: (params) => String(params.row.userprofile.streak),
      renderCell: (record) => {
        return <>{record.row.userprofile.streak}</>;
      },
      sortComparator: (a, b) => a - b,
    },

    {
      field: "complete",
      headerName: "Complete",
      renderCell: (record) => {
        return (
          <Fragment>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: record.row.userprofile.isverified
                  ? "green"
                  : "red",
                borderRadius: "50%",
              }}
            ></div>
          </Fragment>
        );
      },
    },
    {
      field: "completeProfile",
      headerName: "Complete Profile",
      renderCell: (record) => {
        return (
          <Fragment>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: record.row.userprofile.completedInfo
                  ? "green"
                  : "red",
                borderRadius: "50%",
              }}
            ></div>
          </Fragment>
        );
      },
    },
    {
      field: "privateProfile",
      headerName: "Private Profile",
      renderCell: (record) => {
        return (
          <Fragment>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: record.row.userprofile.isprivate
                  ? "green"
                  : "red",
                borderRadius: "50%",
              }}
            ></div>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="Users" show={true} />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && data && (
          <DataGrid
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: "16px", // Adjust the font size as needed
                color: "black",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold", // Adjust the font size as needed
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default Users;
