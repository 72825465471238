import axios from "axios";
import { DistrictAction } from "./DistrictReducers";

export const getDistrict = () => async (dispatch) => {
  try {
    dispatch(DistrictAction.DistrictRequest());

    const res = await axios.get(process.env.REACT_APP_API + "/api/districts");

    dispatch(DistrictAction.DistrictSuccess(res));
  } catch (error) {
    dispatch(DistrictAction.DistrictFail(error));
  }
};
export const AddDistrict = (name) => async (dispatch) => {
  dispatch(DistrictAction.addDistrictRequest());
  const token = localStorage.getItem("jwtToken");
  
  try {
    await axios.post(
      process.env.REACT_APP_API + "/api/districts",
      {
        name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(DistrictAction.addDistrictSuccess());
    dispatch(getDistrict());
  } catch (e) {
    dispatch(DistrictAction.addDistrictFail(e.response.data));
  }
};

export const updateDistrict = (id, name) => async (dispatch) => {
  dispatch(DistrictAction.updateDistrictRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    await axios.put(
      process.env.REACT_APP_API + "/api/districts/" + id,
      {
        name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(DistrictAction.updateDistrictSuccess());
    dispatch(getDistrict());
  } catch (e) {
    dispatch(DistrictAction.updateDistrictFail(e.response.data));
  }
};
export const deleteDistrict = (id) => async (dispatch) => {
  dispatch(DistrictAction.deleteDistrictRequest());
  const token = localStorage.getItem("jwtToken");
  try {
    //id?
    await axios.delete(process.env.REACT_APP_API + "/api/districts/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(DistrictAction.deleteDistrictSuccess());
    dispatch(getDistrict());
  } catch (e) {
    dispatch(DistrictAction.deleteDistrictFail(e.response.data));
  }
};
