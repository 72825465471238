import React , {useEffect,useState} from 'react';
import {useSelector,useDispatch} from 'react-redux';



const NgoCashouts = () => {
    return (
        <>
        NGO Cashouts
        </>
    )
}

export default NgoCashouts;