import { Box } from "@mui/material";
import React from "react";
import Modal from "react-modal";
import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Loading from "../../components/Loading";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { mockDataDonations } from "../../data/mockData";
import { getDonations } from "../../Redux/Donations/DontaionsAction";
import isAuth from "../../Utils/isAuth";
import { useNavigate } from "react-router-dom";

export default function Donations() {
  const dispatch = useDispatch();

  const [records, setRecords] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { donations, loading } = useSelector((state) => state.Donation);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getDonations());
    console.log(donations);
  }, [dispatch]);

  useEffect(() => {
    if (donations?.donations?.length > 0) {
      setRecords(
        donations.donations.map((Donation) => {
          return {
            key: Donation.id,
            id: Donation.id,
            donation_amount: Donation.donation_amount,
            name: Donation.ngo.name,
          };
        })
      );
      console.log(records);
    } else {
      console.log(records);
      setRecords([]);
    }
  }, [donations]);

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "donation_amount",
      headerName: "Donation Amount ",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Header title="DONATIONS" show={true} />
      <Box
        m="40px 0 0 0 "
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            zIndex: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            zIndex: 0,
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            zIndex: 0,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            zIndex: 0,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            zIndex: 0,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            zIndex: 0,
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
            zIndex: 0,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
            zIndex: 0,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && records && (
          <DataGrid
            rows={records}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: "16px", // Adjust the font size as needed
                color: "black",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold", // Adjust the font size as needed
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
}
