import { Box, Icon, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

import SearchIcon from "@mui/icons-material/Search";
import { useState, useRef, useEffect } from "react";
import {
  Nav,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { logoutAdmin } from "../../Redux/Auth/AuthActions";
import { useDispatch } from "react-redux";
import isAuth from "../../Utils/isAuth";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isOpened, setIsOpened] = useState(false);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const toggle = () => setDropDownOpen((prevState) => !prevState);
  const boxRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click target is outside the box
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsOpened(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handleOutsideClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const logout = () => {
    dispatch(logoutAdmin());
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      ></Box>

      {/* ICONS */}
      <Box display="flex" alignItems={"center"} position={"relative"}>
        {isOpened && (
          <Box ref={boxRef}>
            <Button
              onClick={() => {
                logout();
              }}
              style={{
                width: "150px",
                border: "1px solid ",
                fontSize: "14px",
                fontWeight: "900",
                padding: "10px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            >
              LOGOUT
            </Button>
          </Box>
        )}

        <IconButton
          onClick={(event) => {
            setIsOpened(!isOpened);
            event.stopPropagation(); // Stop the event propagation
          }}
        >
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
