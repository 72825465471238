import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";

import Loading from "../../components/Loading";
import "./../../index.css";
import Modal from "react-modal";

import {
  addReward,
  deleteReward,
  getReward,
  getRewardsByChallengeId,
  updateReward,
} from "../../Redux/Reward/RewardActions";

const RewardsByChallenge = () => {
  const dispatch = useDispatch();
  const { Rewards, loading } = useSelector((state) => state.Rewards);

  const { challenge_id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getRewardsByChallengeId(challenge_id));
  }, [dispatch]);

  useEffect(() => {
    if (Rewards) {
      setData(Rewards?.data);
    }
  }, [Rewards]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [challenge, setChallenge] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("winner");
  const [amount, setAmount] = useState("");

  const deleteplanhandle = (id) => {
    dispatch(deleteReward(id));
    setModalIsOpen(false);
    setId("");
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [addmodalIsOpen, setaddModalIsOpen] = useState(false);

  const addopenModal = () => {
    setaddModalIsOpen(true);
  };

  const addcloseModal = () => {
    setaddModalIsOpen(false);
    setedutModalIsOpen(false);
  };
  const addplanhandle = (id, amount, name, type, description) => {
    dispatch(addReward(id, amount, name, type, description));
    setaddModalIsOpen(false);
  };

  const [edutmodalIsOpen, setedutModalIsOpen] = useState(false);

  const editopenModal = () => {
    setedutModalIsOpen(true);
  };

  const editcloseModal = () => {
    setedutModalIsOpen(false);
  };
  const editplanhandle = (id, amount, name, type, description) => {
    dispatch(updateReward(id, amount, name, type, description));
    setedutModalIsOpen(false);
    setChallenge("");
    setId("");
    setAmount();
    setDescription("");
    setName("");
    setType("winner");
  };

  const columns = [
    {
      field: "image",
      headerName: "Profile",
      className: "text-left",
      align: "left",
      sortable: false,
      flex: 1,
      renderCell: (record) => (
        <div className="d-flex  p-2">
          <img
            src={record?.row?.challenge?.image}
            className="rounded-circle"
            alt="avatar"
            width="45"
            height="45"
          />
          <div className="ms-3">
            <div className="mb-0">{record?.row?.name}</div>
          </div>
        </div>
      ),
    },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },

    {
      field: "action",
      headerName: "Action",
      flex: 2,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-carousel-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setName(record.row.name);
                setAmount(record.row.amount);
                setChallenge(record.row.challenge);
                setType(record.row.type);
                setDescription(record.row.description);
                editopenModal();
              }}
            >
              Edit
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions1"
              onClick={() => {
                setId(record.row.id);
                openModal();
              }}
            >
              Delete
            </button>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Modal
        isOpen={edutmodalIsOpen}
        onRequestClose={editcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "35rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Reward</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h5>Type :</h5>
                <select
                  className="inputt col-md-8 form-control cat"
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value={"winner"}>Winner</option>
                  <option value={"participant"}>Participant</option>
                </select>
              </div>

             

              <div className="row formRow inputstyle">
                <h5>Name:</h5>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={name}
                  placeholder="Enter the Name"
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>

              <div className="row formRow inputstyle">
                <h5>Description:</h5>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={description}
                  placeholder="Enter the Description"
                  required
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>

              {type === "participant" && (
                <div className="row formRow inputstyle">
                  <h5>Amount:</h5>
                  <input
                    type="number"
                    className="inputt col-md-8 form-control cat"
                    value={amount}
                    placeholder="Enter the Amount"
                    required
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={editcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() =>
              editplanhandle(id, challenge, amount, name, type, description)
            }
            className="buttoninmodal1"
          >
            Edit Reward
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={addmodalIsOpen}
        onRequestClose={addcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Add modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "35rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Reward</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h5>Type :</h5>
                <select onChange={(e) => setType(e.target.value)}>
                  <option value={"winner"}>Winner</option>
                  <option value={"participant"}>Participant</option>
                </select>

                <h5>Name:</h5>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={name}
                  placeholder="Enter the Name"
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <h5>Description:</h5>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={description}
                  placeholder="Enter the Description"
                  required
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                {type === "participant" && (
                  <>
                    <h5>Amount:</h5>
                    <input
                      type="number"
                      className="  inputt col-md-8 form-control cat"
                      value={amount}
                      placeholder="Enter the Amount"
                      required
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={addcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() =>
              addplanhandle(id, challenge, amount, name, type, description)
            }
            className="buttoninmodal1"
          >
            Add Reward
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="delete modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40rem",
            height: "15rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Delete District</h2>
          <h5>Are You Sure You Want To Delete This Plan ?</h5>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <button onClick={closeModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => deleteplanhandle(id)}
            className="buttoninmodal1"
          >
            Delete
          </button>
        </div>
      </Modal>
      <Header
        title="Rewards For This Challenge"
        buttonText="Add Reward"
        onopen={addopenModal}
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && data && (
          <DataGrid
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: "16px",
                color: "black",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold",
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default RewardsByChallenge;
