import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    Market: [],
};

const MarketSlice = createSlice({
    name: "Market",
    initialState,
    reducers: {
        MarketRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        MarketSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.Market = action.payload;
        },
        addProductSuccess: (state) => {
            state.loading = false;
            state.error = null;
        },
        deleteProductSuccess: (state) => {
            state.loading = false;
            state.error = null;
        },
        editProductSuccess: (state) => {
            state.loading = false;
            state.error = null;
        },


        MarketFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    },
});

export const MarketActions = MarketSlice.actions;
export default MarketSlice;
