

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCashout,Approvec } from "../../Redux/Cashout/CashoutActions";
import { tokens } from "../../theme"
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import Modal from "react-modal";
import Loading from "../../components/Loading";
//utils
import isAuth from "../../Utils/isAuth";
import { Box } from "@mui/material";


const Cashout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
    const colors = tokens(theme.palette.mode);


  const { loading, Cashout, error } =
    useSelector((state) => state.Cashout);

  //Check if user is authenticated
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getCashout());
    console.log("Cashout", Cashout);
  }, [dispatch]);



  const [records, setRecords] = useState([]);
  useEffect(() => {
    if (Cashout?.cashouts) {
      setRecords(
        Cashout?.cashouts.map((data => {
          return {
            key: data.id,
            id: data.id,
            name: data.ngo.name,
            cashout_amount:data.cashout_amount,
            is_paid:data.is_paid ?"Paid" :"Unpaid",
          };
        }))
      );
    }
    setRecords([{key:1,id:2,name:"NgoName",cashout_amount:"Some Money",is_paid:"Unpaid"}]);

  }, [Cashout]);


  const [payModalIsOpen, setPayModalIsOpen] = useState(false);

  const OpenPayModal=()=>{
    setPayModalIsOpen(true);
  }
  const ClosePayModal=()=>{
    setPayModalIsOpen(false);
  }

    const PayHandler = () => {
      dispatch(Approvec(id));
      setPayModalIsOpen(false);
    };

  const [id,setId] =useState("");

  const columns = [
     
    {
      field: 'name',
      headerName: 'NGO',
      flex:0.5,
    },

    {
      field: 'cashout_amount',
      headerName: 'Cashout Amount',
      flex:0.5,
      
    },
 
      {
        field: "approved",
        headerName: "Approved",
        flex:1,
        renderCell: (record) => {
          return (
              <button
                onClick={() => {
                  if (record?.row?.is_paid === "Unpaid") {
                    console.log(record?.row?.id);
                    setId(record?.row?.id);
                    OpenPayModal();
                  }
                }}
    
                className={
                  record?.row?.is_paid === "Paid" ? "greenButton" : "redButton"
                }>{record?.row?.is_paid}</button>
          );
        },
      },
  
    
  ];
  return (
    <Box m="20px">

      {/* Pay Modal */}
      <Modal
            isOpen={payModalIsOpen}
            onRequestClose={ClosePayModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Delete Product</h2>
              <h5>Are You Sure You Want To Pay For this Ngo ?</h5>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={ClosePayModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() => PayHandler()}
                className="buttoninmodal1"
              >
                Pay
              </button>
            </div>
      </Modal>
      <Box
              m="40px 0 0 0 "
              height="75vh"
              sx={{
              "& .MuiDataGrid-root": {
                  border: "none",
                  zIndex: 0,
              },
              overflowX: "scroll",
              "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                  zIndex: 0,
              },
              "& .name-column--cell": {
                  color: colors.greenAccent[300],
                  zIndex: 0,
              },
              "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                  zIndex: 0,
              },
              "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                  zIndex: 0,
              },
              "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                  zIndex: 0,
              },
              "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                  zIndex: 0,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                  zIndex: 0,
              },
              }}
          >
              {loading && <Loading />}
              {!loading && records &&  (
              <DataGrid
                  rows={records}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  sx={{
                    "& .custom-stretch-row": {
                      height: "auto", // Set height to 'auto' to make the rows stretch
                  },
                  "& .MuiDataGrid-cell": {
                      fontSize: "16px", // Adjust the font size as needed
                      color: "black",
                      overflow:"auto"
                  },
                  "& .MuiDataGrid-columnHeader": {
                      fontSize: "16px",
                      color: "black",
                      fontWeight: "bold", // Adjust the font size as needed
                  },
                  }}
                  checkboxSelection={true}
              />
              )}
      </Box>

    </Box>
  );
};

export default Cashout;
