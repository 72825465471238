import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loginAdmin } from "../../Redux/Auth/AuthActions";

const Login = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, isAuthenticated } = useSelector(
    (state) => state.Auth
  );

  useEffect(() => {
    if (isAuthenticated) navigate("/");
  }, [isAuthenticated, navigate]);

  const login = async (e) => {
    e.preventDefault();
    dispatch(loginAdmin(email, password));
  };

  return (
    <Row className="Log">
      <Col>
        <Card className="CardStyle">
          <CardTitle tag="h6" className="border-bottom p-3 mb-0 Logtitle">
            <i className="bi bi-bell me-2"> </i>
            Welcome to DonaLeb’s Impact Analytics Dashboard
          </CardTitle>
          <CardBody>
            <Form>
              <FormGroup>
                <Label for="exampleEmail" className="lableName">
                  Email
                </Label>
                <Input
                  id="exampleEmail"
                  name="email"
                  placeholder="Enter your Email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword" className="lableName">
                  Password
                </Label>
                <Input
                  id="examplePassword"
                  name="password"
                  placeholder="Enter Your Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>

              <Button onClick={login}>Login</Button>
            </Form>
            <a href="forget">Forget Password!</a>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
