
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addAdmin, deleteAdmin, getAdmins, updateAdmin } from "../../Redux/Admin/AdminActions";
import Header from "../../components/Header";

import { tokens } from "../../theme"
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import Modal from "react-modal";
import Loading from "../../components/Loading";

import { Box } from "@mui/material";

//utils
import isAuth from "../../Utils/isAuth";

const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, admins, error } =useSelector((state) => state.Admin);
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getAdmins());
  }, [dispatch]);


  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (admins?.data) {
      setRecords(
        admins?.data.map((data => {
          return {
            key: data.id,
            id: data.id,
            email: data.email,
            phone: data.phone,
            countryCode: data.countryCode,
            role:data.role,
            username:data.adminprofile.username,

          };
        }))
      );
    }
  }, [admins]);

  const [deleteModelIsOpen, setDeleteModalIsOpen] = useState(false);
  const openDeleteModal = () => {
    setDeleteModalIsOpen(true);
  };
  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };
  const DeleteModalHandler = () => {

    dispatch(deleteAdmin(id));
    closeDeleteModal();
  };


  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
  };
  
  const AddModalHandler = () => {
    dispatch(
      addAdmin(
        email,
        password,
        username,
        phone,
        countryCode,
      )
    );

    setEmail("");
    setPassword("");
    setUsername("");
    setCountryCode("");
    setPhone("");

    closeAddModal();
  };



  const [editModelIsOpen, setEditModalIsOpen] = useState(false);

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const EditModalHandler = () => {
    dispatch(updateAdmin(id, username, phone, countryCode))
    
    setEmail("");
    setPassword("");
    setUsername("");
    setCountryCode("");
    setPhone("");

    closeEditModal();
  };

const [email, setEmail] = useState("");
const [username, setUsername] = useState("");
const [password, setPassword] = useState("");
const [phone, setPhone] = useState("");
const [countryCode, setCountryCode] = useState("");
const [id,setId] =useState("");


  
 
 const columns = [
  {
    field: "name",
    headerName: "Profile",
    renderCell: (record) => {
      return (
        <div className="d-flex align-items-center p-2">
          <div className="ms-3">
            <h6 className="mb-0">{record?.row?.email}</h6>
            <span className="text-muted">{record?.row?.username}</span>
          </div>
        </div>
      );
    },
    flex: 1, // Add flex: 1
  },
  {
    field: "phone",
    headerName: "Phone",
    renderCell: (record) => {
      return (
        <div className="d-flex align-items-center p-2">
          <div className="ms-3">
            <h6 className="mb-0">{record?.row?.phone}</h6>
          </div>
        </div>
      );
    },
    flex: 1, // Add flex: 1
  },
  {
    field: "role",
    headerName: "Role",
    renderCell: (record) => {
      return (
        <div className="d-flex align-items-center p-2">
          <div className="ms-3">
            <h6 className="mb-0">{record?.row?.role}</h6>
          </div>
        </div>
      );
    },
    flex: 1, // Add flex: 1
  },
  {
    field: "Action",
    headerName: "Action",
    className: "ac",
    flex: 1, // Add flex: 1
    renderCell: (record) => {
      return (
        <>
          <button
            className="btn btn-success"
            onClick={() => {
              setId(record?.row?.id);
              setUsername(record?.row?.username);
              setPhone(record?.row?.phone);
              setCountryCode(record?.row?.countryCode);
              setEmail(record?.row?.email);
              openEditModal();
            }}
          >
            Edit
          </button>
          &nbsp;
          <button
            className="btn btn-danger"
            onClick={() => {
              setId(record?.row.id);
              openDeleteModal();
            }}
          >
            Delete
          </button>
        </>
      );
    },
  },
];



  return (
    <Box m="20px">

        {/* Add Modal  */}
        <Modal
            isOpen={addModalIsOpen}
            onRequestClose={closeAddModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Edit Company"
            style={{
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
                width: "40%",
                height: "auto",
                margin: "0 auto",
                padding: "20px",
                background: "white",
                borderRadius: "2px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
            },
            }}
        >
            <div>
            <h2 className="modaltitle">Add Admin</h2>
            <form >
        <div className="modal-body">
            <div className="row formRow">
            <h6 >UserName  :</h6>

            <input
                type="text"
                className="  inputt col-md-8 form-control cat"
            
                placeholder="Enter the Username"
                required
                onChange={(e) => {
                setUsername(e.target.value);
                }}
            />
            </div>
            <br />
            <div className="row formRow">
            <h6 >Email  :</h6>

            <input
                type="email"
                className="  inputt col-md-8 form-control cat"
            
                placeholder="Enter the Email"
                required
                onChange={(e) => {
                setEmail(e.target.value);
                }}
            />
            </div>
            <br />
            <div className="row formRow">
            <h6 >password  :</h6>

            <input
                type="text"
                className="  inputt col-md-8 form-control cat"
            
                placeholder="Enter the Password"
                required
                onChange={(e) => {
                setPassword(e.target.value);
                }}
            />
            </div>
            <br />
            <div className="row formRow">
            <h6 >CountryCode  :</h6>

            <input
                type="number"
                className="  inputt col-md-8 form-control cat"
            
                placeholder="Enter the Country Code"
                required
                onChange={(e) => {
                setCountryCode(e.target.value);
                }}
            />
            </div>  
            <br></br><div className="row formRow">
            <h6 >Phone  :</h6>

            <input
                type="number"
                className="  inputt col-md-8 form-control cat"
            
                placeholder="Enter the Phone"
                required
                onChange={(e) => {
                setPhone(e.target.value);
                }}
            />
            </div>
            <br />

            <br />
        </div>
            </form>
            </div>
            <div
            style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
            }}
            >
            <button onClick={closeAddModal} className="buttoninmodal">
                Close
            </button>
            <button onClick={() => AddModalHandler()} className="buttoninmodal1">
                Add Admin
            </button>
            </div>
        </Modal>
        {/* ---------------------------------------------------------------------- */}

    {/* Edit Modal */}
    <Modal
        isOpen={editModelIsOpen}
        onRequestClose={closeEditModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit Admin"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "auto",
            margin: "0 auto",
            padding: "20px",
            background: "white",
            borderRadius: "2px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
            <h2 className="modaltitle">Edit Admin</h2>
            <form >
      <div className="modal-body">
      <div className="row formRow">
          <h6 >UserName  :</h6>

          <input
            type="text"
            className="  inputt col-md-8 form-control cat"
          value={username}
            placeholder="Enter the Username"
            required
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </div>
        <br />
        <div className="row formRow">
          <h6 >CountryCode  :</h6>

          <input
            type="number"
            className="  inputt col-md-8 form-control cat"
            value={countryCode}
            placeholder="Enter the Country Code"
            required
            onChange={(e) => {
              setCountryCode(e.target.value);
            }}
          />
        </div>  
        <br></br><div className="row formRow">
          <h6 >Phone  :</h6>

          <input
            type="number"
            className="  inputt col-md-8 form-control cat"
            value={phone}
            placeholder="Enter the Phone"
            required
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </div>
        <br />

      
        <br />
      </div>
            </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={closeEditModal} className="buttoninmodal">
            Close
          </button>
          <button onClick={() => EditModalHandler()} className="buttoninmodal1">
            Edit Admin
          </button>
        </div>
      </Modal>
      {/* ---------------------------------------------------------------------- */}


        {/* Delete Modal */}
            <Modal
            isOpen={deleteModelIsOpen}
            onRequestClose={closeDeleteModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
            },
            }}
        >
            <div>
            <h2 className="modaltitle">Delete Admin</h2>
            <h5>Are You Sure You Want To Delete This Admin ?</h5>
            </div>
            <div
            style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
            }}
            >
            <button onClick={closeDeleteModal} className="buttoninmodal">
                Close
            </button>
            <button
                onClick={() => DeleteModalHandler()}
                className="buttoninmodal1"
            >
                Delete
            </button>
            </div>
            </Modal>
        {/* ---------------------------------------------------------------------- */}
        
        <Header
        title="ADMINS"
        buttonText={"Add Admin"}
        onopen={openAddModal}
        />
      <Box
              m="40px 0 0 0 "
              height="75vh"
              sx={{
              "& .MuiDataGrid-root": {
                  border: "none",
                  zIndex: 0,
              },
              overflowX: "scroll",
              "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                  zIndex: 0,
              },
              "& .name-column--cell": {
                  color: colors.greenAccent[300],
                  zIndex: 0,
              },
              "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                  zIndex: 0,
              },
              "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                  zIndex: 0,
              },
              "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                  zIndex: 0,
              },
              "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                  zIndex: 0,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                  zIndex: 0,
              },
              }}
          >
              {loading && <Loading />}
              {!loading && records &&  (
              <DataGrid
                  rows={records}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  sx={{
                    "& .custom-stretch-row": {
                      height: "auto", // Set height to 'auto' to make the rows stretch
                  },
                  "& .MuiDataGrid-cell": {
                      fontSize: "16px", // Adjust the font size as needed
                      color: "black",
                      overflow:"auto"
                  },
                  "& .MuiDataGrid-columnHeader": {
                      fontSize: "16px",
                      color: "black",
                      fontWeight: "bold", // Adjust the font size as needed
                  },
                  }}
                  checkboxSelection={true}
              />
              )}
      </Box>

    </Box>
  )
};

export default Admin;
