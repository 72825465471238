import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: "",
    admins: []
}


const AdminSlice = createSlice({
    name: "adminSlice",
    initialState,
    reducers: {
        adminRequest: (state, action) => {
            state.loading = true;
            state.error = "";
        },
        adminSuccess: (state, action) => {
            state.loading = false;
            state.error = "";
            state.admins = action.payload;
        }
        ,
        adminFailure: (state, action) => {
            state.loading = false;
            state.admins = [];
            state.error = action.payload;
        },
    }
})

export const AdminActions = AdminSlice.actions;
export default AdminSlice;