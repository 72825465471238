import axios from "axios";
import { NgoDonationActions } from "./NgoDonationReducers";
import { toast } from 'react-toastify';


export const getDonation = () => async (dispatch) => {
    dispatch(NgoDonationActions.NgoDonationRequest());

    try {
        const token = localStorage.getItem("jwtToken");
        let url = process.env.REACT_APP_API + "/Donation/DonationsByNGO";

        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch(NgoDonationActions.NgoDonationSuccess(response.data));
    } catch (e) {
        dispatch(NgoDonationActions.NgoDonationFail(e.response));
    }
};

export const getDonationsByNGO = () => async (dispatch) => {
    dispatch(NgoDonationActions.NgoDonationRequest());

    try {
        const token = localStorage.getItem("jwtToken");
        let url = process.env.REACT_APP_API + "/Donation/DonationsByNGO";
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch(NgoDonationActions.NgoDonationSuccess(response.data));
    } catch (e) {
        dispatch(NgoDonationActions.NgoDonationFail(e.response));
    }
};

export const Approvec = (cashout_amount) => async (dispatch) => {

    dispatch(NgoDonationActions.NgoDonationRequest());

    const token = localStorage.getItem("jwtToken");
    console.log(process.env.REACT_APP_API + "/cashouts#/cashouts");
    if (cashout_amount >= 5) {
        try {
            let url = process.env.REACT_APP_API + "/Cashout/createcashout";
            const response = await axios.post(
                url,
                { cashout_amount },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            dispatch(NgoDonationActions.CashoutDonationSuccess(response.data));
            window.location.href = "/#/cashouts"
            toast.success("Cashout requested successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500,
            });

            dispatch(getDonation());
        } catch (e) {
            dispatch(NgoDonationActions.NgoDonationFail(e.response));
            toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500,
            });
        }
    } else {
        const errorMessage = "Cashout amount must be greater than five.";
        dispatch(NgoDonationActions.NgoDonationFail({ data: { message: errorMessage } }));
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
        });
    }
};

