import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    Quotes: [],
    addQuote: [],
    updateQuote: [],
    deleteQuote: [],
    fetchSuccess1: [],
};

const QuoteSlice = createSlice({
    name: "Quote",
    initialState,
    reducers: {
        QuoteRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        QuoteSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.Quotes = action.payload;
        },
        addQuoteSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.addQuote = action.payload;
        },
        deleteQuoteSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.deleteQuote = action.payload;
        },
        editQuoteSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.updateQuote = action.payload;
        },
        fetchSuccess1: (state, action) => {
            state.loading = false;
            state.error = null;
            state.fetchSuccess1 = action.payload;
        },
        addconditionSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        deleteconditionSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        editconditionSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        clearSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },

        QuoteFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    },
});

export const QuoteAction = QuoteSlice.actions;
export default QuoteSlice;
