import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  ngos: [],
  error: "",
};

const NgoSlice = createSlice({

  name: "Ngo",
  initialState,
  reducers: {
    NgoRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.ngos = [];
    },
    NgoSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.ngos = action.payload;
    },
    addNgoSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.ngos = action.payload;
    },
    editNgoSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.ngos = action.payload;
    },
    deleteNgoSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.ngos = action.payload;
    },

    NgoFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.ngos = [];
    },

  },
});


export const NgoActions = NgoSlice.actions;
export default NgoSlice; 