import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { Box, Typography } from '@mui/material';
import StatBox from '../../components/StatBox';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCompanyByToken } from '../../Redux/Company/CompanyActions';



const Company_profile = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const token = localStorage.getItem("jwtToken");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {companyProfile} = useSelector((state) => state.Company);
    const [record,setRecord]=useState({})




    useEffect(()=>{
        dispatch(getCompanyByToken(token))
    },[dispatch])


    useEffect(()=>{
        setRecord(companyProfile?.data)
        console.log({RECORD:record})
    },[companyProfile])

    const boxStyle = {
        border: '1px solid #ddd',
        padding: '16px',
        marginBottom: '16px',
      };
    


    return(
        <Box m="20px" >

            <Header
                title="PROFILE"
                buttonText={"Edit Profile"}

            />

            <Box ml="190px" >
            
                <Box style={{padding:"2%"}}>
                    <Typography style={{display:"flex" , gap:"50px"}}>
                        <Typography style={{fontWeight:"bold",fontSize:"41px" }} >
                            Email:
                        </Typography>
                        
                        <Typography style={{fontSize:"41px" }}  >
                            {record?.email}
                        </Typography>
                        
                    </Typography>
                    <Typography style={{display:"flex" , gap:"50px"}}>
                        <Typography style={{fontWeight:"bold",fontSize:"41px" }} >
                            Role:
                        </Typography>
                        
                        <Typography style={{fontSize:"41px" }}  >
                            {record?.role}
                        </Typography>
                        
                    </Typography>
                    <Typography style={{display:"flex" , gap:"50px"}}>
                        <Typography style={{fontWeight:"bold",fontSize:"41px" }} >
                            Joined on:
                        </Typography>
                        
                        <Typography style={{fontSize:"41px" }}  >
                        {new Date(record?.createdAt).toLocaleDateString()}
                        </Typography>
                        
                    </Typography>

                    <Typography style={{display:"flex" , gap:"50px"}}>
                        <Typography style={{fontWeight:"bold",fontSize:"41px" , color:colors.primary[100]}} >
                            Company Code:
                        </Typography>
                        
                        <Typography style={{fontSize:"41px" }}  >
                        {record?.companyprofile?.company_code}
                        </Typography>
                        
                    </Typography>

                    <Typography style={{display:"flex" , gap:"50px"}}>
                        <Typography style={{fontWeight:"bold",fontSize:"41px" }} >
                            Employees Count:
                        </Typography>
                        
                        <Typography style={{fontSize:"41px" }}  >
                        {record?.companyprofile?.employeesCount}
                        </Typography>
                        
                    </Typography>
                    
                    
                {/* You can add more details from the companyprofile object*/}
                </Box>

            <Box mt={3}>
                
            </Box>

            {/* <Box mt={3} style={{maxWidth:"50%"}}>
                <Typography variant="h6">Company Image</Typography>
                <img src={record.companyprofile.image} alt="Company Logo" style={{ maxWidth: '100%' }} />
            </Box> */}
            </Box>
    
        </Box>
    )
}

export default Company_profile ; 