import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    NgoDonation: [],
    CashRes: [],
};

const NgoDonationSlice = createSlice({
    name: "NgoDonation",
    initialState,
    reducers: {
        NgoDonationRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        NgoDonationSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.NgoDonation = action.payload;
        },
        CashoutDonationSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.CashRes = action.payload;
        },
        NgoDonationFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    },
});

export const NgoDonationActions = NgoDonationSlice.actions;
export default NgoDonationSlice;
