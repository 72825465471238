import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  contactus: [],
};

const ContactSlice = createSlice({
  name: "Contact  ",
  initialState,
  reducers: {
    ContactRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    ContactSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.contactus = action.payload;
    },

    ContactFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const ContactAction = ContactSlice.actions;
export default ContactSlice;
