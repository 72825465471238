import { AdminActions } from "./AdminReducers";
import axios from "axios"

export const getAdmins = () => async (dispatch) => {
    dispatch(AdminActions.adminRequest());
    try {
        const res = await axios.get(process.env.REACT_APP_API+"/admin/getalladmin");
        
        dispatch(AdminActions.adminSuccess(res.data));
    } catch (error) {
        dispatch(AdminActions.adminFailure(error.message));
    }
}

export const addAdmin =(email, username, password, phone, countryCode) =>
     async (dispatch) =>
     {
        dispatch(AdminActions.adminRequest());
        try {
        
        const res = await axios.post(process.env.REACT_APP_API + "/admin/createadmin", {
            email,
            username,
            password,
            phone,
            countryCode,
        });
        dispatch(AdminActions.adminSuccess());
        dispatch(getAdmins());
        } catch (e) {
        dispatch(AdminActions.adminFailure(e.response.data));
        }
  };

  export const updateAdmin =(id, username, phone, countryCode) =>
  async (dispatch) =>
   {

          dispatch(AdminActions.adminRequest());
          try {
          const res = await axios.put(
              process.env.REACT_APP_API + "/admin/updateadminbyid/" + id,
              {
              username,
              phone,
              countryCode,
              }
          );

          console.log({"HERE" :res.status})
          dispatch(AdminActions.adminSuccess());
          dispatch(getAdmins());
          } catch (e) {
          dispatch(AdminActions.adminFailure(e.response.data));
          }
};



export const deleteAdmin = (id) => async (dispatch) => {
    dispatch(AdminActions.adminRequest());
    try {
        const res =  await axios.delete(process.env.REACT_APP_API+"/admin/deleteadminbyid/"+id);
        
        dispatch(AdminActions.adminSuccess());
        dispatch(getAdmins())
    } catch (error) {
        dispatch(AdminActions.adminFailure(error.message));
    }
}