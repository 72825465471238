import axios from "axios";
import { CharityActions } from "./CharityReducers";

export const getCharity = () => async (dispatch) => {
    dispatch(CharityActions.CharityRequest());
    try {
        let url = process.env.REACT_APP_API + "/Charity/getCharities";
        const response = await axios.get(url);
        dispatch(CharityActions.CharitySuccess(response.data));
    } catch (e) {
        dispatch(CharityActions.CharityFail(e.response));
    }
};

export const addCharity = (name) => async (dispatch) => {
    dispatch(CharityActions.CharityRequest());
    try {
        await axios.post(process.env.REACT_APP_API + "/Charity/createcharity", {
            name,
        });
        dispatch(CharityActions.addCharitySuccess());
        dispatch(getCharity());
    } catch (e) {
        dispatch(CharityActions.CharityFail(e.response.data));
    }
};


export const deleteCharity = (id) => async (dispatch) => {
    dispatch(CharityActions.CharityRequest());
    try {
        await axios.delete(process.env.REACT_APP_API + "/Charity/deleteCharity/" + id);
        dispatch(CharityActions.deleteCharitySuccess());
        dispatch(getCharity());
    } catch (e) {
        dispatch(CharityActions.CharityFail(e.response.data));
    }
};



export const updateCharity = (id, name) => async (dispatch) => {
    dispatch(CharityActions.CharityRequest());
    try {
        await axios.put(process.env.REACT_APP_API + "/Charity/updatecharity/" + id, {
            name,
        });
        dispatch(CharityActions.editCharitySuccess());
        dispatch(getCharity());
    } catch (e) {
        dispatch(CharityActions.CharityFail(e.response.data));
    }
};