import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    departments: [],
};

const DepartmentSlice = createSlice({
    name: "Department",
    initialState,
    reducers: {
        DepartmentRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        DepartmentSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.departments = action.payload;
        },
        
        DepartmentFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const DepartmentActions = DepartmentSlice.actions;
export default DepartmentSlice;

