import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../../index.css";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import {
  deletecondition,
  getcondition,
  updatecondition,
} from "../../Redux/Privacy/PrivacyActions";
import isAuth from "../../Utils/isAuth";

const Privacy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Privacy, loading } = useSelector((state) => state.Privacy);

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getcondition());
  }, [dispatch]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (Privacy) {
      setData(Privacy.privacy);
    }
  }, [Privacy]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [policy, setPolicy] = useState("");

  const deleteplanhandle = (id) => {
    dispatch(deletecondition(id));
    setModalIsOpen(false);
    setId("");
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  //   const [addmodalIsOpen, setaddModalIsOpen] = useState(false);

  //   const addopenModal = () => {
  //     setaddModalIsOpen(true);
  //   };

  //   const addcloseModal = () => {
  //     setaddModalIsOpen(false);
  //     setedutModalIsOpen(false);
  //   };
  //   const addplanhandle = (policy) => {
  //     dispatch(addcondition(policy));
  //     setaddModalIsOpen(false);
  //     setPolicy("");
  //   };

  const [edutmodalIsOpen, setedutModalIsOpen] = useState(false);

  const editopenModal = () => {
    setedutModalIsOpen(true);
  };

  const editcloseModal = () => {
    setedutModalIsOpen(false);
  };

  const editplanhandle = (id, quote) => {
    dispatch(updatecondition(id, quote));
    setedutModalIsOpen(false);
    setPolicy("");
    setId("");
  };

  const columns = [
    {
      field: "Policy",
      headerName: "Policy",
      flex: 1,
    },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-carousel-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setPolicy(record.row.Policy);
                editopenModal();
              }}
            >
              Edit
            </button>
            {/* <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions1"
              onClick={() => {
                setId(record.row.id);
                openModal();
              }}
            >
              Delete
            </button> */}
          </Fragment>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Modal
        isOpen={edutmodalIsOpen}
        onRequestClose={editcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "20rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Policy</h2>
          <form>
            <div sx={{ height: "20rem" }} className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Policy:</h6>
                <textarea
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={policy}
                  placeholder="Enter the Policy"
                  required
                  style={{ height: "10rem" }}
                  onChange={(e) => {
                    setPolicy(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={editcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => editplanhandle(id, policy)}
            className="buttoninmodal1"
          >
            Edit Policy
          </button>
        </div>
      </Modal>
      {/* <Modal
        isOpen={addmodalIsOpen}
        onRequestClose={addcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Add modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "15rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Policy</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Policy:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={policy}
                  placeholder="Enter the Quote"
                  required
                  onChange={(e) => {
                    setPolicy(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={addcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => addplanhandle(Privacy)}
            className="buttoninmodal1"
          >
            Add Policy
          </button>
        </div>
      </Modal> */}
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="delete modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40rem",
            height: "15rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Delete District</h2>
          <h5>Are You Sure You Want To Delete This Plan ?</h5>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <button onClick={closeModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => deleteplanhandle(id)}
            className="buttoninmodal1"
          >
            Delete
          </button>
        </div>
      </Modal> */}

      <Header
        title="Privacy"
        // onopen={addopenModal}
        show={true}
      />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && data && (
          <DataGrid
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: "16px", // Adjust the font size as needed
                color: "black",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold", // Adjust the font size as needed
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default Privacy;
