import { jwtDecode } from "jwt-decode";
//redux
import { logoutAdmin } from "../Redux/Auth/AuthActions";
import { AuthenticationActions } from "../Redux/Auth/AuthReducers";

//utils
import setAuthToken from "./setAuthToken";

const isAuth = (dispatch) => {
  const token = localStorage.jwtToken;
 
  if (token) {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      dispatch(logoutAdmin());
      setAuthToken(false);
      return false;
    }
    setAuthToken(token);
    dispatch(AuthenticationActions.authLoginSuccess());
    return true;
  }
  return false;
};

export default isAuth;
