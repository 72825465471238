import { Typography, Box, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";
import "./../scenes/Style.css";

const Header = ({ title, subtitle, buttonText, onopen, show }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb="30px"
    >
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>

      {!show && (
        <button
          style={{ width: "130px" }}
          className="addbutton"
          onClick={onopen}
        >
          {buttonText}
        </button>
      )}
    </Box>
  );
};

export default Header;
