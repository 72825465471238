import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../../index.css";
import Modal from "react-modal";

import isAuth from "../../Utils/isAuth";
import { useNavigate } from "react-router-dom";
import { getCharity } from "../../Redux/Charity/CharityActions";
import {
  addNgo,
  deleteNgo,
  getNgo,
  updateNgo,
} from "../../Redux/Ngo/NgoActions";

const Ngo = () => {
  const dispatch = useDispatch();
  const { ngos, loading } = useSelector((state) => state.Ngo);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { Charity } = useSelector((state) => state.Charity);

  const [charitylist, setcharitylist] = useState([]);

  // Authantication function
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  //call the Ngo data
  useEffect(() => {
    dispatch(getNgo());
    dispatch(getCharity());
  }, [dispatch]);

  // Setting the data
  useEffect(() => {
    if (ngos) {
      setData(ngos?.data?.ngos?.rows);
    }
  }, [ngos]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");

  const [image, setImage] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [additionalImages, setAdditionalImages] = useState([]);

  // add the images from the add Function
  const handleAdditionalImagesChange = (e) => {
    setAdditionalImages((prevImages) => [...prevImages, ...e.target.files]);
  };

  const deleteplanhandle = (id) => {
    dispatch(deleteNgo(id));
    setModalIsOpen(false);
    setId("");
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [addmodalIsOpen, setaddModalIsOpen] = useState(false);

  const addopenModal = () => {
    setaddModalIsOpen(true);
  };

  const addcloseModal = () => {
    setaddModalIsOpen(false);
    setedutModalIsOpen(false);
    setId("");
    setImage();
    setEmail("");
    setPassword("");
    setUrl("");
    setDescription("");
    setName("");

    setLocation("");
  };
  const [edutmodalIsOpen, setedutModalIsOpen] = useState(false);

  const editopenModal = () => {
    setedutModalIsOpen(true);
  };

  const editcloseModal = () => {
    setedutModalIsOpen(false);
    setId("");
    setImage();
    setEmail("");
    setPassword("");
    setUrl("");
    setDescription("");
    setName("");

    setLocation("");
  };

  //Edit Ngo
  const editplanhandle = (id, url, description, name, location) => {
    dispatch(updateNgo(id, url, description, name, location));
    setedutModalIsOpen(false);
    setId("");
    setImage();
    setEmail("");
    setPassword("");
    setUrl("");
    setDescription("");
    setName("");

    setLocation("");
  };

  //Add Ngo

  const AddNgohandle = (
    files,
    email,
    password,
    url,
    description,
    name,
    charitylist,
    image,
    location
  ) => {
    dispatch(
      addNgo(
        files,
        email,
        password,
        url,
        description,
        name,
        charitylist,
        image,
        location
      )
    );

    setImage();
    setEmail("");
    setPassword("");
    setUrl("");
    setDescription("");
    setName("");

    // toggle1();
  };

  // The column setup
  const handleAddCharity = (event) => {
    const charityId = event.target.value;

    // Check if the charity ID is already in the list
    if (charitylist.includes(charityId)) {
      // If it's in the list, remove it
      setcharitylist((previousList) =>
        previousList.filter((id) => id !== charityId)
      );
    } else {
      // If it's not in the list, add it
      setcharitylist((previousList) => [...previousList, charityId]);
    }
  };

  const columns = [
    {
      field: "avatar",
      headerName: "Avatar",
      renderCell: (record) => {
        return (
          <img
            style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
            src={`${process.env.REACT_APP_API}/ngo/${record?.row?.logo}`}
            alt="ImageNotFound"
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "url",
      headerName: "Url",
      width: 200,
      renderCell: (record) => {
        return (
          <a href={`${record.row.url}`} target="_blank">
            {record.row.url}
          </a>
        );
      },
    },
    { field: "description", headerName: "Description", width: 500 },
    { field: "donationNumber", headerName: "Donation" },
    {
      field: "Category",
      headerName: "Category",
      width: 300,
      renderCell: (record) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              overflowX: "auto",
            }}
          >
            {record?.row?.ngo_charities?.map((item) => {
              return <span>{item?.charity?.name}</span>;
            })}
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-carousel-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setUrl(record.row.url);
                setDescription(record.row.description);
                setName(record.row.name);
                setLocation(record.row.location);
                editopenModal();
              }}
            >
              Edit
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions1"
              onClick={() => {
                setId(record.row.id);
                openModal();
              }}
            >
              Delete
            </button>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      {/* Edit model */}
      <Modal
        isOpen={edutmodalIsOpen}
        onRequestClose={editcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Edit Ngo</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Description :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Description"
                  required
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                <h6>Url :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Url"
                  required
                  value={url}
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                />
                <h6>Name :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Name"
                  required
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <h6>Location :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Location"
                  required
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={editcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => editplanhandle(id, url, description, name, location)}
            className="buttoninmodal1"
          >
            Edit Ngo
          </button>
        </div>
      </Modal>

      {/* Add model */}
      <Modal
        isOpen={addmodalIsOpen}
        onRequestClose={addcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Add modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "50%",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
            overflow: "auto",
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Ngo</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Cover Image:</h6>
                <input
                  type="file"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                />
                <h6>Image:</h6>
                <input
                  type="file"
                  onChange={(e) => handleAdditionalImagesChange(e)}
                />
                <h6>Categories :</h6>
                {Charity.charities?.map((ch) => (
                  <label key={ch.id} className="checkboxLabel">
                    <input
                      type="checkbox"
                      value={ch.id}
                      checked={charitylist?.includes(ch?.id.toString())}
                      onChange={handleAddCharity}
                    />
                    {ch.name}
                  </label>
                ))}
                <h6>Name :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Name"
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <h6>Description :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Description"
                  required
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                <h6>Url :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Url"
                  required
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                />
                <h6>Location :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Location"
                  required
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                />
                <h6>Email :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Email"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <h6>Password :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Password"
                  required
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={addcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() =>
              AddNgohandle(
                additionalImages,
                email,
                password,
                url,
                description,
                name,
                charitylist,
                image,
                location
              )
            }
            className="buttoninmodal1"
          >
            Add Ngo
          </button>
        </div>
      </Modal>

      {/* Delete model */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="delete modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40rem",
            height: "15rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Delete Ngo</h2>
          <h5>Are You Sure You Want To Delete Ngo ?</h5>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <button onClick={closeModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => deleteplanhandle(id)}
            className="buttoninmodal1"
          >
            Delete
          </button>
        </div>
      </Modal>
      <Header title="Ngo" buttonText="Add Ngo" onopen={addopenModal} />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && data && (
          <DataGrid
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: "16px", // Adjust the font size as needed
                color: "black",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold", // Adjust the font size as needed
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default Ngo;
