import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Faq: [],
  addfaq: [],
  updateFaq: [],
  deleteFaq: [],
};

const FaqSlice = createSlice({
  name: "Faq",
  initialState,
  reducers: {
    FaqRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.Faq = [];
    },
    FaqSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.Faq = action.payload;
    },

    FaqFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.Faq = [];
    },
    addFaqRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    addFaqSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addFaq = action.payload;
    },

    addFaqFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateFaqRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateFaqSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.updateFaq = action.payload;
    },

    updateFaqFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteFaqRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deleteFaqSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteFaq = action.payload;
    },

    deleteFaqFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const FaqAction = FaqSlice.actions;
export default FaqSlice;
