import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../../index.css";
import Modal from "react-modal";

import {
  addReward,
  deleteReward,
  getReward,
  updateReward,
} from "../../Redux/Reward/RewardActions";
import { useNavigate } from "react-router-dom";
import isAuth from "./../../Utils/isAuth";

const Reward = () => {
  const dispatch = useDispatch();
  const { Rewards, loading } = useSelector((state) => state.Rewards);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getReward());
  }, [dispatch]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (Rewards) {
      // setData(Quotes?.data?.districts);
    }
  }, [Rewards]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [quote, setquote] = useState("");
  const [challenge, setChallenge] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("winner");
  const [amount, setAmount] = useState("");

  const deleteplanhandle = (id) => {
    dispatch(deleteReward(id));
    setModalIsOpen(false);
    setId("");
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [addmodalIsOpen, setaddModalIsOpen] = useState(false);

  const addopenModal = () => {
    setaddModalIsOpen(true);
  };

  const addcloseModal = () => {
    setaddModalIsOpen(false);
    setedutModalIsOpen(false);
    setquote("");
  };
  const addplanhandle = (id, amount, name, type, description) => {
    dispatch(addReward(id, amount, name, type, description));
    setaddModalIsOpen(false);
    setquote("");
  };

  const [edutmodalIsOpen, setedutModalIsOpen] = useState(false);

  const editopenModal = () => {
    setedutModalIsOpen(true);
  };

  const editcloseModal = () => {
    setedutModalIsOpen(false);
    setquote("");
  };
  const editplanhandle = (id, amount, name, type, description) => {
    dispatch(updateReward(id, amount, name, type, description));
    setedutModalIsOpen(false);
    setChallenge("");
    setId("");
    setAmount();
    setDescription("");
    setName("");
    setType("winner");
  };

  const columns = [
    { field: "challenge", headerName: "Challenge" },
    { field: "name", headerName: "Name" },
    { field: "description", headerName: "Description" },
    { field: "type", headerName: "Type" },
    { field: "amount", headerName: "Amount" },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-carousel-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setName(record.row.name);
                setAmount(record.row.amount);
                setChallenge(record.row.challenge);
                setType(record.row.type);
                setDescription(record.row.description);
                editopenModal();
              }}
            >
              Edit
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="actions1"
              onClick={() => {
                setId(record.row.id);
                openModal();
              }}
            >
              Delete
            </button>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Modal
        isOpen={edutmodalIsOpen}
        onRequestClose={editcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "35rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Reward</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Type :</h6>
                <select onChange={(e) => setType(e.target.value)}>
                  <option value={"winner"}>Winner</option>
                  <option value={"participant"}>Participant</option>
                </select>
                <h6>Ngo :</h6>
                <select onChange={(e) => setChallenge(e.target.value)}>
                  <option value={""} selected>
                    please Enter a challenge
                  </option>
                </select>
                <h6>Name:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={name}
                  placeholder="Enter the Name"
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <h6>Description:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={description}
                  placeholder="Enter the Description"
                  required
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                {type === "participant" && (
                  <>
                    <h6>Amount:</h6>
                    <input
                      type="number"
                      className="  inputt col-md-8 form-control cat"
                      value={amount}
                      placeholder="Enter the Amount"
                      required
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={editcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() =>
              editplanhandle(id, challenge, amount, name, type, description)
            }
            className="buttoninmodal1"
          >
            Edit Reward
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={addmodalIsOpen}
        onRequestClose={addcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Add modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "35rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Reward</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Type :</h6>
                <select onChange={(e) => setType(e.target.value)}>
                  <option value={"winner"}>Winner</option>
                  <option value={"participant"}>Participant</option>
                </select>
                <h6>Ngo :</h6>
                <select onChange={(e) => setChallenge(e.target.value)}>
                  <option value={""} selected>
                    please Enter a challenge
                  </option>
                </select>
                <h6>Name:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={name}
                  placeholder="Enter the Name"
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <h6>Description:</h6>
                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={description}
                  placeholder="Enter the Description"
                  required
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                {type === "participant" && (
                  <>
                    <h6>Amount:</h6>
                    <input
                      type="number"
                      className="  inputt col-md-8 form-control cat"
                      value={amount}
                      placeholder="Enter the Amount"
                      required
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={addcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() =>
              addplanhandle(id, challenge, amount, name, type, description)
            }
            className="buttoninmodal1"
          >
            Add Reward
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="delete modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40rem",
            height: "15rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Delete Reward</h2>
          <h5>Are You Sure You Want To Delete This Reward ?</h5>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <button onClick={closeModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => deleteplanhandle(id)}
            className="buttoninmodal1"
          >
            Delete
          </button>
        </div>
      </Modal>
      <Header title="Rewards" buttonText="Add Reward" onopen={addopenModal} />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && data && (
          <DataGrid
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: "16px", // Adjust the font size as needed
                color: "black",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold", // Adjust the font size as needed
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default Reward;
