import axios from "axios";
import { MarketActions } from "./MarketReducers";

export const getMarket = () => async (dispatch) => {
    dispatch(MarketActions.MarketRequest());
    try {
        let url = process.env.REACT_APP_API + "/Marketplace/getAllMarketplaceProducts";
        const response = await axios.get(url);
        dispatch(MarketActions.MarketSuccess(response.data));
    } catch (e) {
        dispatch(MarketActions.MarketFail(e.response));
    }
};


export const addProduct =
    (
        name,
        price,
        description,
        category,
        ordercode,
        orderdescription,
        timestart,
        timeend,
        stockamount,
        about,
        additionalImages
    ) =>
        async (dispatch) => {
            console.log(name, price, description, category, ordercode, orderdescription, timestart, timeend, about, stockamount);
            const formData = new FormData();
            for (const image of additionalImages) {
                formData.append("image", image);
            }
            formData.append("category", category);
            formData.append("price", price);
            formData.append("ordercode", ordercode);

            formData.append("description", description);
            formData.append("name", name);

            formData.append("orderdescription", orderdescription);
            formData.append("timestart", timestart);
            formData.append("timeend", timeend);
            formData.append("about", about);
            formData.append("stockamount", stockamount);

            dispatch(MarketActions.MarketRequest())
            try {
                await axios.post(process.env.REACT_APP_API + "/Marketplace/createMarketplaceProduct", formData);
                dispatch(MarketActions.addProductSuccess());
                dispatch(getMarket());
            } catch (e) {
                dispatch(MarketActions.MarketFail(e.response.data));
            }
        };



export const editMarket =
    (
        id,
        name,
        price,
        description,
        category,
        ordercode,
        orderdescription,
        timestart,
        timeend,
        stockamount,
        about
    ) =>
        async (dispatch) => {


            dispatch(MarketActions.MarketRequest())
            try {
                await axios.put(process.env.REACT_APP_API + "/Marketplace/updateMarketplaceProduct/" + id, {
                    name, price, description, category, ordercode, orderdescription, timestart, timeend, about, stockamount
                });
                dispatch(MarketActions.editProductSuccess());
                dispatch(getMarket());
            } catch (e) {
                dispatch(MarketActions.MarketFail(e.response.data));
            }
        };



export const deleteProduct = (id) => async (dispatch) => {
    dispatch(MarketActions.MarketRequest())

    try {
        const url = await axios.delete(
            process.env.REACT_APP_API + "/Marketplace/deleteMarketplaceProduct/" + id
        );

        dispatch(MarketActions.deleteProductSuccess());
        dispatch(getMarket());
    } catch (e) {
        dispatch(MarketActions.MarketFail(e.response.data));
    }
};


export const addImage = (id, img) => async (dispatch) => {
    const formData = new FormData();
    formData.append('image', img);

    dispatch(MarketActions.MarketRequest());

    const urlPost = `${process.env.REACT_APP_API}/Marketplace/createMarketplaceProductImage/${id}`;

    try {
        // Pass formData directly as the second argument
        const response = await axios.post(urlPost, formData);

        dispatch(MarketActions.deleteProductSuccess());
        dispatch(getMarket());
    } catch (e) {
        dispatch(MarketActions.MarketFail(e.response.data));
    }
};




export const deleteImage = (id, imageId) => async (dispatch) => {

    dispatch(MarketActions.MarketRequest())

    try {
        await axios.delete(`${process.env.REACT_APP_API}/Marketplace/deleteProductImage/${id}`, {
            data: { imageId },
        });

        dispatch(MarketActions.deleteProductSuccess());
        dispatch(getMarket());



    } catch (e) {
        dispatch(MarketActions.MarketFail(e.response.data));
    }
};