import { AuthenticationActions } from "./AuthReducers";
import axios from "axios";

import setAuthToken from "../../Utils/setAuthToken";

export const loginAdmin = (email, password) => async (dispatch) => {
  dispatch(AuthenticationActions.authRequest());
  try {
    const user = await axios.post(process.env.REACT_APP_API + "/user/loginAdmin", {
      email,
      password,
    });
    console.log(user.data.data.token);
    localStorage.setItem("jwtToken", user.data.data.token);
    localStorage.setItem("id", user.data.data.user.id);
    localStorage.setItem("email", user.data.data.user.email);
    localStorage.setItem("role", user.data.data.user.role);

    dispatch(AuthenticationActions.authLoginSuccess());
  } catch (e) {
    alert(e.response.data.message)
    dispatch(AuthenticationActions.authFail(e.response.data));
  }
};



export const ForgetPasswordAction = (email) => async (dispatch) => {
  dispatch(AuthenticationActions.authRequest());
  try {
    const res = await axios.post(process.env.REACT_APP_API + "/user/ForgetPassword", {
      email,
    });
    dispatch(AuthenticationActions.authForgetPassword(res))
  } catch (e) {

    dispatch(AuthenticationActions.authFail(e.response.data));

  }

}



export const logoutAdmin = () => (dispatch) => {
  localStorage.removeItem("id");
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("email");
  localStorage.removeItem("role");
  localStorage.removeItem("logged");
  localStorage.removeItem("user");
  localStorage.removeItem("token");

  setAuthToken(false);
  dispatch(AuthenticationActions.authLogout());
  window.location.href = "/login";
};