// NotFoundPage.js
import React from "react";
import { Box, Typography } from "@mui/material";

const NotFoundPage = () => {
  return (
    <Box
      className="custom-box"
      sx={{
        backgroundColor: "#f5f5f5", // Use your desired background color
        padding: "20px",
      }}
    >
      <Typography variant="h4" color="textPrimary">
        Page Not Found
      </Typography>
      <Typography variant="body1" color="textSecondary">
        The requested page could not be found.
      </Typography>
    </Box>
  );
};

export default NotFoundPage;
