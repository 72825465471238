import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    Notification: [],
};

const NotificationSlice = createSlice({
    name: "Notification",
    initialState,
    reducers: {
        NotificationRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        NotificationSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.Notification = action.payload;
        },
        addNotificationSuccess: (state) => {
            state.loading = false;
            state.error = null;
        },
        deleteNotificationSuccess: (state) => {
            state.loading = false;
            state.error = null;
        },
        editNotificationSuccess: (state) => {
            state.loading = false;
            state.error = null;
        },

        NotificationFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    },
});

export const NotificationAction = NotificationSlice.actions;
export default NotificationSlice;
