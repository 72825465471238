import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import StatBox from "../../components/StatBox";
import React, { useEffect,useState } from "react";
import isAuth from "../../Utils/isAuth";
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { getStatistics } from "../../Redux/Statistics/StatisticsActions";
import { IconsAndData } from "./dashboardIcons";
import ProgressCircle from "../../components/ProgressCircle";




const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [record,setRecord]=useState([]);
  const [topUsers,setTopUsers]= useState([])
  const [userChart,setUserChart]=useState([])

  useEffect(() => {
    console.log("IS NOT AUTHENTICATED")
    if(!isAuth(dispatch)){
      navigate("/login")
    }
  },[dispatch,navigate])

  const {statistics} = useSelector((state) => state.Statistics);

  useEffect(() => {
    dispatch(getStatistics());
    
  },dispatch)

  useEffect(() =>{
    setRecord(statistics)
    record.map(record=>{
        if(record.key === "topUsers"){
          setTopUsers(record.value)
        }
        if(record.key === "UserChart"){
          setUserChart(record.value)
        }
      }
      )
    },[statistics])
    

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" show={true} />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="16px"
      >
        {/* ROW 1 ICONS */}
        
        {record.map((oneRecord) =>
                (oneRecord.key !== "topUsers" && oneRecord.key !== "UserChart")  && (
                    <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    key={oneRecord.key}  // Add a unique key for each element in the map
                    >
                        <StatBox
                            
                            title={String(oneRecord.value)}
                            subtitle={
                            IconsAndData[oneRecord.key] !== undefined
                                ? IconsAndData[oneRecord.key]?.title
                                : ""
                            }
                            progress="0.75"
                            increase="+14%"
                            icon= {React.cloneElement(IconsAndData[oneRecord.key]?.icon, {
                                style: {
                                  color: colors.greenAccent[600],
                                  fontSize: "26px" 

                                },
                              })}
                              
                        />
                    
                    </Box>
                )
                )}

        {/* ROW 2 */}{/* LINECHART */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            
            <Box>
              <Typography
                variant="h5"
                fontWeight="900"
                fontSize={"20px"}
                color={colors.grey[100]}
              >
                Statistics
              </Typography>
              
            </Box>

            {/* <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box> */}
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} Data={userChart} />
          </Box>
        </Box>

        {/* ROW 2 */}{/* TOPUSERS */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="800" fontSize={"20px"} >
             Top Users
            </Typography>
          </Box>
          {topUsers.map((oneRecord, i) => (
                <Box
                    key={oneRecord.id}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`4px solid ${colors.primary[500]}`}
                    p="15px"
                  >
                    <Box>
                      <Typography
                        color={colors.greenAccent[500]}
                        variant="h5"
                        fontWeight="600"
                      >
                        {oneRecord.username}
                      </Typography>
                      <Typography color={colors.grey[100]}>
                        {oneRecord.steps}
                      </Typography>
                    </Box>
                    <Box color={colors.grey[100]}>{oneRecord.date}</Box>
                    <Box
                      backgroundColor={colors.greenAccent[500]}
                      p="5px 10px"
                      borderRadius="4px"
                    >
                      {oneRecord.streak}
                    </Box>
                </Box>
              )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
