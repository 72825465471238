import React from "react";
import "./../scenes/Style.css";

function Loading() {
  const divStyles = {
    transform:
      "translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
  };

  return (
    <div className="Loading">
      <div style={divStyles}></div>
    </div>
  );
}

export default Loading;
