import { Box } from "@mui/material";
import React from "react";
import Modal from "react-modal";
import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGiftChallenges,
  AddGiftChallenge,
  deleteGiftChallenge,
  updateGiftChallenge,
  addWinnerReward,
  addParticipantReward,
} from "../../Redux/giftChallenge/giftChallengeActions";
import moment from "moment";
import isAuth from "../../Utils/isAuth";
import { useNavigate } from "react-router-dom";

import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Loading from "../../components/Loading";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";

export default function GiftChallenges() {
  const dispatch = useDispatch();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { giftchallenges, loading } = useSelector(
    (state) => state.GiftChallenge
  );
  //gift challenge variables
  const [id, setId] = useState("");
  const [goal, setGoal] = useState("");
  const [timestart, setTimeStart] = useState("");
  const [timeend, setTimeEnd] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [giftcode, setGiftCode] = useState("");
  const [emaildescription, setEmailDescription] = useState("");
  const [winnerNumber, setWinnerNumber] = useState("");
  const [company_id, setCompanyId] = useState("");
  const [image, setImage] = useState();

  //reward variables
  const validTypes = ["participant", "winner"];
  const [name, setName] = useState("");
  const [rewardDescription, setRewardDescription] = useState("");
  const [type, setType] = useState("participant");
  const [createdId, setCreatedID] = useState("");
  const [rewardImage, setRewardImage] = useState();
  const [amount, setAmount] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getGiftChallenges());
  }, [dispatch]);

  const [records, setRecords] = useState([]);
  useEffect(() => {
    if (giftchallenges?.data) {
      setRecords(giftchallenges?.data?.challenges);
    }
  }, [giftchallenges]);

  //add modal
  const [addModal, setAddModal] = useState(false);
  const toggleAddModal = () => {
    setAddModal(!addModal);
    setGoal("");
    setTimeStart("");
    setTimeEnd("");
    setDescription("");
    setTitle("");
    setGiftCode("");
    setEmailDescription("");
    setWinnerNumber("");
    setCompanyId("");
    setImage();
  };
  // add gift challenge handle
  const addGiftChallengeHandle = (
    image,
    goal,
    timestart,
    timeend,
    description,
    title,
    giftcode,
    emaildescription,
    winnerNumber
  ) => {
    dispatch(
      AddGiftChallenge(
        image,
        goal,
        timestart,
        timeend,
        description,
        title,
        giftcode,
        emaildescription,
        winnerNumber
      )
    )
      .then((response) => {
        console.log("response:", response);
        const createdId = response?.data?.data?.newGiftChallenge?.challenge_id;
        setCreatedID(createdId);
        console.log("Created Challenge ID:", createdId);
      })
      .catch((error) => {
        console.error("Error creating gift challenge:", error);
      });
    setAddModal(false);
    winnerRewardToggle();
    setGoal("");
    setTimeStart("");
    setTimeEnd("");
    setDescription("");
    setTitle("");
    setGiftCode("");
    setEmailDescription("");
    setWinnerNumber("");
    setCompanyId("");
    setImage();
  };

  const handleImageUpload = (event) => {
    console.log(event);
    setRewardImage(event.target.files[0]);
  };
  // winner reward funtions
  const [winnerRewardModal, setWinnerRewardModal] = useState(false);
  const winnerRewardToggle = () => {
    setWinnerRewardModal(!winnerRewardModal);
  };

  const handleAddWinnerReward = (
    createdId,
    name,
    rewardDescription,
    type,
    rewardImage
  ) => {
    if (createdId && name && rewardDescription && type && rewardImage) {
      dispatch(
        addWinnerReward(createdId, name, rewardDescription, type, rewardImage)
      );

      setName("");
      setRewardDescription("");
      setType("participant");
      setRewardImage(null);
      console.log(createdId);
    }
    winnerRewardToggle();
    toggleParticipanRewardModal();
  };

  const [participanRewardModal, setParicipantRewardModal] = useState(false);
  const toggleParticipanRewardModal = () => {
    setParicipantRewardModal(!participanRewardModal);
  };

  const addParticipantRewardHandle = (createdId, amount, type) => {
    console.log(createdId, amount, type);
    if (createdId && amount && type) {
      dispatch(addParticipantReward(createdId, amount, type));
      setCreatedID("");
      setAmount("");
      setType("");
    }
    toggleParticipanRewardModal();
  };
  //Edit gift challenge
  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const editGiftChallengeHandle = (
    id,
    image,
    goal,
    timestart,
    timeend,
    description,
    title,
    giftcode,
    emaildescription,
    winnerNumber
  ) => {
    dispatch(
      updateGiftChallenge(
        id,
        image,
        goal,
        timestart,
        timeend,
        description,
        title,
        giftcode,
        emaildescription,
        winnerNumber
      )
    );
    setId("");
    setGoal("");
    setTimeStart("");
    setTimeEnd("");
    setDescription("");
    setTitle("");
    setGiftCode("");
    setEmailDescription("");
    setWinnerNumber("");
    setCompanyId("");
    setImage();
    toggleEditModal();
  };

  //delete modal
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handlePhotoUpload = (event) => {
    setImage(event.target.files[0]);
  };

  const deleteGiftChallengeHandle = (id) => {
    dispatch(deleteGiftChallenge(id));
    setId("");
    toggleDeleteModal();
  };

  //rewards by id navigation

  const checkRewardsHandle = (id) => {
    navigate(`/rewardsByChallenge/${id}`);
  };

  const columns = [
    {
      field: "image",
      headerName: "Profile",
      className: "text-left",
      align: "left",
      sortable: false,
      flex: 1,
      renderCell: (record) => (
        <div className="d-flex  p-2">
          <img
            src={record?.row?.image}
            className="rounded-circle"
            alt="avatar"
            width="45"
            height="45"
          />
          <div className="ms-3">
            <div className="mb-0">{record?.row?.title}</div>
          </div>
        </div>
      ),
    },

    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    // {
    //   field: "sponsoredby",
    //   headerName: "Sponsor",
    //   flex: 1,
    // },
    {
      field: "goal",
      headerName: "Goal",
      flex: 0.5,
    },
    {
      field: "timestart",
      headerName: "Time",
      flex: 1,
      renderCell: (record) => (
        <div>
          <div style={{ whiteSpace: "nowrap", margin: "6px" }}>
            From:{" "}
            {moment(record?.row?.timestart.split("T")[0]).format(
              "DD MMMM YYYY"
            )}
          </div>
          <div style={{ whiteSpace: "nowrap", margin: "6px" }}>
            Until:{" "}
            {moment(record?.row?.timeend.split("T")[0]).format("DD MMMM YYYY")}
          </div>
        </div>
      ),
    },

    {
      field: "giftcode",
      headerName: "Gift Code",
      flex: 0.5,
      renderCell: (record) => record?.row?.giftchallenge?.giftcode,
    },
    {
      field: "winnerNumber",
      headerName: "Winner Number",
      flex: 1,
      renderCell: (record) => record?.row?.giftchallenge?.winnerNumber,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-carousel-modal"
              className="btn"
              style={{
                marginRight: "5px",
                backgroundColor: "#5d50ff",
                color: "white",
              }}
              onClick={() => {
                setId(record?.row?.id);
                setGoal(record?.row?.goal);
                setTimeStart(record?.row?.timestart);
                setTimeEnd(record?.row?.timeend);
                setDescription(record?.row?.description);
                setTitle(record?.row?.title);
                setGiftCode(record?.row?.giftchallenge?.giftcode);
                setEmailDescription(record?.row?.giftchallenge?.emaildescription);
                setWinnerNumber(record?.row?.giftchallenge?.winnerNumber);
                setCompanyId(record?.row?.company_id);
                setImage(record?.row?.image);
                toggleEditModal();
              }}
            >
              Edit
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-carousel-modal"
              className="btn btn-danger"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record?.row?.id);
                toggleDeleteModal();
              }}
            >
              Delete
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
               
                checkRewardsHandle(record?.row?.id);
              }}
            >
              Check Rewards
            </button>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="Gift Challenges"
        buttonText="Add Challenge"
        onopen={toggleAddModal}
      />
      {/* ADD MODAL  */}
      <Modal
        isOpen={addModal}
        toggle={toggleAddModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Add modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            // height: "20rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Gift Challenge</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Image:</h6>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                />
                {/* 
                {image && (
                  <img
                    src={URL.createObjectURL(image)}
                    alt="Uploaded photo"
                    style={{
                      width: "300px",
                      height: "200px",
                      marginTop: "10px",
                    }}
                  />
                )} */}
              </div>

              <div className="row formRow inputstyle">
                <h6>Title:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={title}
                  placeholder="Enter the title"
                  required
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div className="row formRow inputstyle">
                <h6>Description:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={description}
                  placeholder="Enter the description"
                  required
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className="row formRow inputstyle">
                <h6>Goal:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={goal}
                  placeholder="Enter the goal"
                  required
                  onChange={(e) => {
                    setGoal(e.target.value);
                  }}
                />
              </div>

              <div className="row formRow inputstyle">
                <h6>Time Start:</h6>
                <input
                  type="Date"
                  className="inputt col-md-8 form-control cat"
                  value={timestart}
                  placeholder="Enter the start time"
                  required
                  onChange={(e) => {
                    setTimeStart(e.target.value);
                  }}
                />
              </div>

              <div className="row formRow inputstyle">
                <h6>Time End:</h6>
                <input
                  type="Date"
                  className="inputt col-md-8 form-control cat"
                  value={timeend}
                  placeholder="Enter the end time"
                  required
                  onChange={(e) => {
                    setTimeEnd(e.target.value);
                  }}
                />
              </div>

              <div className="row formRow inputstyle">
                <h6>Gift Code:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={giftcode}
                  placeholder="Enter the gift code"
                  required
                  onChange={(e) => {
                    setGiftCode(e.target.value);
                  }}
                />
              </div>

              <div className="row formRow inputstyle">
                <h6>Email For Winner :</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={emaildescription}
                  placeholder="Send Email For The Winner"
                  required
                  onChange={(e) => {
                    setEmailDescription(e.target.value);
                  }}
                />
              </div>

              <div className="row formRow inputstyle">
                <h6>Winner Number:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={winnerNumber}
                  placeholder="Enter the winner number"
                  required
                  onChange={(e) => {
                    setWinnerNumber(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={() => setAddModal(false)} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() =>
              addGiftChallengeHandle(
                image,
                goal,
                timestart,
                timeend,
                description,
                title,
                giftcode,
                emaildescription,
                winnerNumber
              )
            }
            className="buttoninmodal1"
          >
            Next{" "}
          </button>
        </div>
      </Modal>
      {/* Winner Reward Modal */}
      <Modal
        isOpen={winnerRewardModal}
        toggle={winnerRewardToggle}
        shouldCloseOnOverlayClick={false}
        contentLabel="Next modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Reward For Winner</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Reward Name:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={name}
                  placeholder="Enter the name"
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>

              <div className="row formRow inputstyle">
                <h6>Description:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={rewardDescription}
                  placeholder="Enter the description"
                  required
                  onChange={(e) => {
                    setRewardDescription(e.target.value);
                  }}
                />
              </div>
              <div className="row formRow inputstyle">
                <h6>Image:</h6>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                {/* {rewardImage && (
                  <img
                    src={URL.createObjectURL(rewardImage)}
                    alt="Uploaded photo"
                    style={{
                      width: "300px",
                      height: "200px",
                      marginTop: "10px",
                    }}
                  />
                )} */}
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button
            onClick={() => setWinnerRewardModal(false)}
            className="buttoninmodal"
          >
            Close
          </button>
          <button
            onClick={() =>
              handleAddWinnerReward(
                createdId,
                name,
                rewardDescription,
                "winner",
                rewardImage
              )
            }
            className="buttoninmodal1"
          >
            Next{" "}
          </button>
        </div>
      </Modal>

      {/* Participant Reward Modal */}
      <Modal
        isOpen={participanRewardModal}
        onRequestClose={addParticipantReward}
        shouldCloseOnOverlayClick={false}
        contentLabel="Amount Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Participant Reward</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Amount:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={amount}
                  placeholder="Enter the amount"
                  required
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button
            onClick={() => toggleParticipanRewardModal}
            className="buttoninmodal"
          >
            Close
          </button>
          <button
            onClick={() =>
              addParticipantRewardHandle(createdId, amount, "participant")
            }
            className="buttoninmodal1"
          >
            Add
          </button>
        </div>
      </Modal>
      {/* EDIT MODAL */}
      <Modal
        isOpen={editModal}
        toggle={toggleEditModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Edit Gift Challenge</h2>
          <form>
            <div className="modal-body modalview">
              {/* Image Input */}
              <div className="row formRow inputstyle">
                <h6>Image:</h6>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                />
                {/* {image && (
                  <img
                    src={URL.createObjectURL(image)}
                    alt="Uploaded photo"
                    style={{
                      width: "300px",
                      height: "200px",
                      marginTop: "10px",
                    }}
                   /> 
               )}  */}
              </div>

              {/* Goal Input */}
              <div className="row formRow inputstyle">
                <h6>Goal:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={goal}
                  placeholder="Enter the goal"
                  required
                  onChange={(e) => {
                    setGoal(e.target.value);
                  }}
                />
              </div>

              {/* Time Start Input */}
              <div className="row formRow inputstyle">
                <h6>Time Start:</h6>
                <input
                  type="Date"
                  className="inputt col-md-8 form-control cat"
                  value={moment(timestart).format("YYYY-MM-DD")}
                  placeholder="Enter the start time"
                  required
                  onChange={(e) => {
                    setTimeStart(e.target.value);
                  }}
                />
              </div>

              {/* Time End Input */}
              <div className="row formRow inputstyle">
                <h6>Time End:</h6>
                <input
                  type="Date"
                  className="inputt col-md-8 form-control cat"
                  value={moment(timeend).format("YYYY-MM-DD")}
                  placeholder="Enter the end time"
                  required
                  onChange={(e) => {
                    setTimeEnd(e.target.value);
                  }}
                />
              </div>

              {/* Description Input */}
              <div className="row formRow inputstyle">
                <h6>Description:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={description}
                  placeholder="Enter the description"
                  required
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>

              {/* Title Input */}
              <div className="row formRow inputstyle">
                <h6>Title:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={title}
                  placeholder="Enter the title"
                  required
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>

              {/* Gift Code Input */}
              <div className="row formRow inputstyle">
                <h6>Gift Code:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={giftcode}
                  placeholder="Enter the gift code"
                  required
                  onChange={(e) => {
                    setGiftCode(e.target.value);
                  }}
                />
              </div>

              {/* Email Description Input */}
              <div className="row formRow inputstyle">
                <h6>Email For Winner:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={emaildescription}
                  placeholder="Enter Email To Send"
                  required
                  onChange={(e) => {
                    setEmailDescription(e.target.value);
                  }}
                />
              </div>

              {/* Winner Number Input */}
              <div className="row formRow inputstyle">
                <h6>Winner Number:</h6>
                <input
                  type="text"
                  className="inputt col-md-8 form-control cat"
                  value={winnerNumber}
                  placeholder="Enter the winner number"
                  required
                  onChange={(e) => {
                    setWinnerNumber(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={() => toggleEditModal()} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() =>
              editGiftChallengeHandle(
                id,
                image,
                goal,
                timestart,
                timeend,
                description,
                title,
                giftcode,
                emaildescription,
                winnerNumber
              )
            }
            className="buttoninmodal1"
          >
            Save Changes
          </button>
        </div>
      </Modal>

      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="delete modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40rem",
            height: "15rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Delete Challenge</h2>
          <h5>Are You Sure You Want To Delete This Challenge ?</h5>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <button onClick={() => toggleDeleteModal()} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => {
              deleteGiftChallengeHandle(id);
            }}
            className="buttoninmodal1"
          >
            Delete
          </button>
        </div>
      </Modal>
      <Box
        m="40px 0 0 0 "
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            zIndex: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            zIndex: 0,
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            zIndex: 0,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            zIndex: 0,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            zIndex: 0,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            zIndex: 0,
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
            zIndex: 0,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
            zIndex: 0,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && records && (
          <DataGrid
            rows={records}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: "16px",
                color: "black",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold",
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
}
