import { Box } from "@mui/material";
import React from "react";
import Modal from "react-modal";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Loading from "../../components/Loading";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import isAuth from "../../Utils/isAuth";
import { useNavigate } from "react-router-dom";
import {
  Approvec,
  getDonationsByNGO,
} from "../../Redux/NgoDonation/NgoDonationActions";

export default function NgoDonation() {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { NgoDonation, loading } = useSelector((state) => state.NgoDonation);

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getDonationsByNGO());
  }, [dispatch]);

  useEffect(() => {
    if (NgoDonation?.donations) {
      setData(NgoDonation?.donations);
    }
  }, [NgoDonation]);

  console.log(data);

  const addPlanHandle = (amount) => {
    if (amount) console.log("Amount is: ", amount);

    dispatch(Approvec(amount));
    setAddModalIsOpen(false);

    setAmount(0);
  };

  const [addmodalIsOpen, setAddModalIsOpen] = useState(false);

  const addopenModal = () => {
    setAddModalIsOpen(true);
  };

  const addcloseModal = () => {
    setAddModalIsOpen(false);
    setAmount(0);
  };

  const columns = [
    {
      field: "avatar",
      headerName: "Profile",
      renderCell: (record) => {
        return (
          <img
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            src={record.row.user.userprofile.avatar}
            alt="ImageNotFound"
          />
        );
      },
    },
    {
      field: "username",
      headerName: "Name",
      flex: 1,
      renderCell: (record) => {
        return <p>{record.row.user.userprofile.username}</p>;
      },
    },
    {
      field: "donation_amount",
      headerName: "Donation Amount",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Create At",
      flex: 1,
      renderCell: (record) => {
        const originalDateString = record.row.createdAt;
        const dateObject = new Date(originalDateString);

        const formattedDate = new Intl.DateTimeFormat("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }).format(dateObject);
        return <p>{formattedDate}</p>;
      },
    },
  ];

  return (
    <Box m="20px">
      <Modal
        isOpen={addmodalIsOpen}
        onRequestClose={addcloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "20rem",

            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Request Cashout</h2>
          <form>
            <div className="modal-body modalview">
              <div className="row formRow inputstyle">
                <h6>Cashout Amount :</h6>
                <input
                  type="number"
                  className="  inputt col-md-8 form-control cat"
                  value={amount}
                  placeholder="Enter the amount"
                  required
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>

        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={addcloseModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => addPlanHandle(amount)}
            className="buttoninmodal1"
          >
            Request
          </button>
        </div>
      </Modal>

      <Header
        title="Donation"
        buttonText="Request Cashout"
        onopen={addopenModal}
      />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            zIndex: 0,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            zIndex: 0,
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            zIndex: 0,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            zIndex: 0,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            zIndex: 0,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            zIndex: 0,
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
            zIndex: 0,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
            zIndex: 0,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && data && (
          <DataGrid
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: "16px", // Adjust the font size as needed
                color: "black",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold", // Adjust the font size as needed
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
}
