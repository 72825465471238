import { Box } from "@mui/material";
import Modal from "react-modal";
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import { tokens } from "../../theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import {
  getCompany,
  addCompany,
  updateCompany,
  deleteCompany,
} from "../../Redux/Company/CompanyActions";
//utils
import isAuth from "../../Utils/isAuth";
import { getDepartment } from "../../Redux/Department/DepartmentActions";
import { SettingsSuggestRounded } from "@mui/icons-material";

const Company = () => {
  function Display(key, value) {
    useEffect(() => {
      console.log({ key: value });
    }, [value]);
  }

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companies, loading, error } = useSelector((state) => state.Company);
  const { departments } = useSelector((state) => state.Departments);

  //Check if user is authenticated
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getCompany());
    dispatch(getDepartment());
  }, [dispatch]);

  Display("companies", companies);

  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (companies?.data) {
      setRecords(
        companies?.data?.map((comp) => {
          return {
            key: comp.id,
            id: comp.id,
            username: comp.companyprofile.username,
            email: comp.email ? comp.email : "Email Not Available",
            phone: comp.phone ? comp.phone : "Phone Not Available",
            steps: comp.companyprofile.totalsteps
              ? comp.companyprofile.totalsteps
              : "No Step Yet",
            emloyeenb: comp.companyprofile.employeesCount
              ? comp.companyprofile.employeesCount
              : "No Employee Yet",
            image: comp.companyprofile.image,
            userId: comp.companyprofile.user_id,
            companyid: comp.companyprofile.id,
            companycode: comp.companyprofile.company_code,
          };
        })
      );
    }
  }, [companies]);

  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const openAddModal = () => {
    setAddModalIsOpen(true);
  };
  const closeAddModal = () => {
    setAddModalIsOpen(false);
  };
  const AddModalHandler = () => {
    dispatch(
      addCompany(
        email,
        password,
        username,
        image,
        departmentlist,
        country,
        location
      )
    );

    setEmail("");
    setPassword("");
    setUsername("");
    setDepartment_id("");
    setCountry("");
    setLocation("");

    closeAddModal();
  };

  const [deleteModelIsOpen, setDeleteModalIsOpen] = useState(false);
  const openDeleteModal = () => {
    setDeleteModalIsOpen(true);
  };
  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };
  const DeleteModalHandler = () => {
    dispatch(deleteCompany(id));
    closeDeleteModal();
  };

  const [editModelIsOpen, setEditModalIsOpen] = useState(false);
  const openEditModal = () => {
    setEditModalIsOpen(true);
  };
  const closeEditModal = () => {
    setId();
    setImage("");
    setEmail("");
    setPassword("");
    setUsername("");
    setDepartment_id("");
    setCountry("");
    setLocation("");
    setEditModalIsOpen(false);
  };
  const EditModalHandler = () => {
    if (image === null || email.trim() === "" || username.trim() === "") {
      return;
    }

    //Dispatch the updateCompany action with the non-empty values
    dispatch(
      updateCompany(
        userId,
        company_code.trim(),
        email.trim(),
        image,
        username.trim()
      )
    );

    // Clear the state values
    setId();
    setEmail("");
    setPassword("");
    setUsername("");
    setDepartment_id("");
    setCountry("");
    setLocation("");

    closeEditModal();
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [department_id, setDepartment_id] = useState("");
  const [company_code, setCompany_code] = useState("");
  const [userId, setUserId] = useState("");
  const [country, setCountry] = useState("");
  const [location, setLocation] = useState("");
  const [id, setId] = useState();
  const [image, setImage] = useState();
  const [departmentlist, setdepartmentlist] = useState([]);

  const handleAddCharity = (event) => {
    const departmentId = event.target.value;

    // Check if the department ID is already in the list
    if (departmentlist.includes(departmentId)) {
      // If it's in the list, remove it
      setdepartmentlist((previousList) =>
        previousList.filter((id) => id !== departmentId)
      );
    } else {
      // If it's not in the list, add it
      setdepartmentlist((previousList) => [...previousList, departmentId]);
    }
  };

  const columns = [
    {
      field: "image",
      headerName: "Profile",
      flex: 1,
      sortable: false,
      renderCell: (record) => {
        return (
          <div className="d-flex align-items-center p-2">
            <img
              src={record?.row.image}
              className="rounded-circle"
              alt="avatar"
              width="45"
              height="45"
            />

            <div className="ms-3">
              <h6 className="mb-0">{record?.email}</h6>
              <span className="text-muted">{record?.phone} </span>
            </div>
          </div>
        );
      },
    },

    {
      field: "steps",
      headerName: "Steps",
      flex: 1,
    },

    {
      field: "emloyeenb",
      headerName: "Employee Count",
      flex: 1,
    },

    {
      field: "companycode",
      headerName: "Code",
    },

    {
      field: "desc",
      headerName: "Description",
      className: "ac",
      flex: 1,
      renderCell: (record) => (
        <>
          <button
            className="btn btn-warning "
            onClick={() => {
              navigate(`/companyEmployees/${record?.row?.companyid}`);
            }}
          >
            View Employees
          </button>
        </>
      ),
    },

    {
      field: "Action",
      headerName: "Action",
      className: "ac",
      flex: 1,
      renderCell: (record) => {
        return (
          <>
            <button
              className="btn btn-success"
              onClick={() => {
                setId(record?.row.companyid);
                setEmail(record?.row.email);
                setCountry(record?.row.country);
                setLocation(record?.row.location);
                setUsername(record?.row?.username);
                setUserId(record?.row?.userId);
                setCompany_code(record?.row?.companycode);
                openEditModal();
              }}
            >
              Edit
            </button>
            &nbsp;
            <button
              className="btn btn-danger"
              onClick={() => {
                setId(record?.row.companyid);
                openDeleteModal();
              }}
            >
              Delete
            </button>
          </>
        );
      },
    },
  ];

  const handlePhotoUpload = (event) => {
    setImage(event.target.files[0]);
  };

  return (
    <Box m="20px">
      {/* Edit Modal */}
      <Modal
        isOpen={editModelIsOpen}
        onRequestClose={closeEditModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit Company"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "auto",
            margin: "0 auto",
            padding: "20px",
            background: "white",
            borderRadius: "2px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Edit Company</h2>
          <form>
            <div className="modal-body">
              <h4 className="description">Image</h4>

              <div className="row formRow">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                />

                {image && (
                  <img
                    src={URL.createObjectURL(image)}
                    alt="Uploaded photo"
                    style={{
                      width: "300px",
                      height: "200px",
                      marginTop: "10px",
                    }}
                  />
                )}
              </div>
              <br></br>

              <br></br>
              <div className="row formRow">
                <h6>Company Code :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={company_code}
                  placeholder="Enter the Code"
                  required
                  onChange={(e) => {
                    setCompany_code(e.target.value);
                  }}
                />
              </div>
              <br />

              <div className="row formRow">
                <h6>Username :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  value={username}
                  placeholder="Enter the Username"
                  required
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </div>
              <br></br>
              <div className="row formRow">
                <h6>Email :</h6>

                <input
                  type="text"
                  value={email}
                  className=" selectg inputt col-md-8 form-control cat"
                  placeholder="Enter the Email"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <br></br>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={closeEditModal} className="buttoninmodal">
            Close
          </button>
          <button onClick={() => EditModalHandler()} className="buttoninmodal1">
            Edit Company
          </button>
        </div>
      </Modal>
      {/* ---------------------------------------------------------------------- */}

      {/* Delete Modal */}
      <Modal
        isOpen={deleteModelIsOpen}
        onRequestClose={closeDeleteModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="delete modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40rem",
            height: "15rem",
            margin: "0 auto",
            padding: "10px",
            background: "white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Delete Company</h2>
          <h5>Are You Sure You Want To Delete This Company ?</h5>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <button onClick={closeDeleteModal} className="buttoninmodal">
            Close
          </button>
          <button
            onClick={() => DeleteModalHandler()}
            className="buttoninmodal1"
          >
            Delete
          </button>
        </div>
      </Modal>
      {/* ---------------------------------------------------------------------- */}

      {/* Add Modal  */}
      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={closeAddModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit Company"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            height: "auto",
            margin: "0 auto",
            padding: "20px",
            background: "white",
            borderRadius: "2px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <h2 className="modaltitle">Add Company</h2>
          <form>
            <div className="modal-body">
              <h4 className="description">Image</h4>

              <div className="row formRow">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoUpload}
                />

                {image && (
                  <img
                    src={URL.createObjectURL(image)}
                    alt="Uploaded photo"
                    style={{
                      width: "300px",
                      height: "200px",
                      marginTop: "10px",
                    }}
                  />
                )}
              </div>
              <br></br>
              <h4 className="description">Info</h4>
              <div className="row formRow">
                <h6>Department :</h6>
                {departments?.data?.newDepartment?.map((ch) => (
                  <label key={ch.id} className="checkboxLabel">
                    <input
                      type="checkbox"
                      value={ch.id}
                      checked={departmentlist.includes(ch?.id.toString())}
                      onChange={handleAddCharity}
                    />
                    {ch.name}
                  </label>
                ))}
              </div>

              <br></br>
              <div className="row formRow">
                <h6>Email :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Email"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <br />
              <div className="row formRow">
                <h6 className="col-md-3 textForm">Password :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Password"
                  required
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <br></br>
              <div className="row formRow">
                <h6>Username :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Username"
                  required
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </div>
              <br></br>
              <div className="row formRow">
                <h6>Country :</h6>

                <input
                  type="text"
                  className="  inputt col-md-8 form-control cat"
                  placeholder="Enter the Country"
                  required
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                />
              </div>
              <br></br>
              <div className="row formRow">
                <h6>Location :</h6>

                <input
                  type="text"
                  className=" selectg inputt col-md-8 form-control cat"
                  placeholder="Enter the Location"
                  required
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                />
              </div>
              <br></br>
            </div>
          </form>
        </div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button onClick={closeAddModal} className="buttoninmodal">
            Close
          </button>
          <button onClick={() => AddModalHandler()} className="buttoninmodal1">
            Add Company
          </button>
        </div>
      </Modal>
      {/* ---------------------------------------------------------------------- */}

      <Header
        title="COMPANIES"
        buttonText={"Add Company"}
        onopen={openAddModal}
      />

      <Box
        m="40px 0 0 0 "
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            zIndex: 0,
          },
          overflowX: "scroll",
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            zIndex: 0,
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            zIndex: 0,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            zIndex: 0,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            zIndex: 0,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            zIndex: 0,
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
            zIndex: 0,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
            zIndex: 0,
          },
        }}
      >
        {loading && <Loading />}
        {!loading && records && (
          <DataGrid
            rows={records}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              "& .custom-stretch-row": {
                height: "auto", // Set height to 'auto' to make the rows stretch
              },
              "& .MuiDataGrid-cell": {
                fontSize: "16px", // Adjust the font size as needed
                color: "black",
                overflow: "auto",
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "16px",
                color: "black",
                fontWeight: "bold", // Adjust the font size as needed
              },
            }}
            checkboxSelection={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default Company;
