    import { Box } from '@mui/material';
    import Modal from "react-modal";
    import Header from "../../components/Header";
    import Loading from "../../components/Loading";
    import { tokens } from "../../theme"
    import { DataGrid, GridToolbar } from "@mui/x-data-grid";
    import { useTheme } from "@mui/material";
    import React, { useState, useEffect, Fragment } from "react";
    import { useDispatch, useSelector } from "react-redux";
    import { useNavigate } from "react-router-dom";
    
    import { getCompanyEmployee } from "../../Redux/Company/CompanyActions";
    
    //useparam
    import { useParams } from "react-router-dom";
    
    //utils
    import isAuth from "../../Utils/isAuth";
  
  export const CompanyEmployees = () => {
    const { id } = useParams();

    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { companyEmployees,loading } = useSelector((state) => state.Company);
    useEffect(() => {
      if (!isAuth(dispatch)) navigate("/");
    }, [dispatch, navigate]);


    const { departments } = useSelector((state) => state.Departments);


    useEffect(() => {
      dispatch(getCompanyEmployee(id));
    }, [dispatch]);


    const [records, setRecords] = useState([]);
  
    useEffect(() => {
        setRecords(companyEmployees?.data?.companyprofile?.companyusers);
    }, [companyEmployees]);

    const columns = [
        {
          field: "name",
          headerName: "Profile",
          sortable: true,
          renderCell: (record) => {
            return (
              <div className="d-flex align-items-center p-2">
                {!record?.row?.userprofile?.avatar ? (
                  "No image"
                ) : (
                  <img
                    src={
                      process.env.REACT_APP_API +
                      "/users/" +
                      record?.row?.userprofile?.avatar
                    }
                    className="rounded-circle"
                    alt="avatar"
                    width="45"
                    height="45"
                  />
                )}
              </div>
            );
          },
        },
      
        {
          field: "username",
          headerName: "Username",
          sortable: true,
          renderCell: (record) => {
            return (
              <h6 className="mb-0">
                {record?.row?.userprofile?.username
                  ? record?.row?.userprofile?.username
                  : "No Username"}
              </h6>
            );
          },
        },
      
        {
          field: "emails",
          headerName: "Emails",
          sortable: true,
          renderCell: (record) => {
            return (
              <div className="d-flex align-items-center p-2">
                {record?.row?.userprofile?.user?.email}
              </div>
            );
          },
        },
      
        {
          field: "phone",
          headerName: "Phone",
          sortable: true,
          renderCell: (record) => {
            return (
              <div className="d-flex align-items-center p-2">
                {record?.row?.userprofile?.user?.phone
                  ? record?.row?.userprofile?.user?.phone
                  : "No Phone"}
              </div>
            );
          },
        },
      
        {
          field: "steps",
          headerName: "Steps",
          sortable: true,
          renderCell: (record) => {
            return (
              <div className="d-flex align-items-center p-2">
                {record?.row?.userprofile?.steps ? record?.row?.userprofile?.steps : "0"}
              </div>
            );
          },
        },
        {
          field: "empSteps",
          headerName: "EmpSteps",
          sortable: true,
        },
      
        {
          field: "wallet",
          headerName: "Wallet",
          sortable: true,
          renderCell: (record) => {
            return (
              <div className="d-flex align-items-center p-2">
                {record?.row?.userprofile?.wallet ? record?.row?.userprofile?.wallet : "0"}
              </div>
            );
          },
        },
      
        {
          field: "streak",
          headerName: "Streak",
          sortable: true,
          renderCell: (record) => {
            return (
              <div className="d-flex align-items-center p-2">
                {record?.row?.userprofile?.streak ? record?.row?.userprofile?.streak : "0"}
              </div>
            );
          },
        },
        {
          field: "verified",
          headerName: "Verified",
          sortable: true,
          renderCell: (record) => {
            return record?.row?.userprofile?.isverified === true ? (
              <span className="p-2 bg-success rounded-circle d-inline-block ms-3"></span>
            ) : (
              <span className="p-2 bg-danger rounded-circle d-inline-block ms-3"></span>
            );
          },
        },
      
        {
          field: "complete",
          headerName: "Complete",
          sortable: true,
          renderCell: (record) => {
            return record?.row?.userprofile?.completedInfo === true ? (
              <span className="p-2 bg-success rounded-circle d-inline-block ms-3"></span>
            ) : (
              <span className="p-2 bg-danger rounded-circle d-inline-block ms-3"></span>
            );
          },
        },
        {
          field: "private",
          headerName: "Private",
          sortable: true,
          renderCell: (record) => {
            return record?.row?.userprofile?.isprivate === true ? (
              <span className="p-2 bg-success rounded-circle d-inline-block ms-3"></span>
            ) : (
              <span className="p-2 bg-danger rounded-circle d-inline-block ms-3"></span>
            );
          },
        },
      
        {
          field: "feeling",
          headerName: "Feeling",
          sortable: true,
          renderCell: (record) => {
            return (
              <div className="d-flex align-items-center p-2">
                {record?.row?.employeefeeling?.feeling}
              </div>
            );
          },
        },
      ];
      
  
    return (
        <Box m="20px" 
        >
          <Header title="EMPLOYEES" show={true}  />
            <Box
                m="40px 0 0 0 "
                height="75vh"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                    zIndex: 0,
                },
                overflowX: "scroll",
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                    zIndex: 0,
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                    zIndex: 0,
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    zIndex: 0,
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                    zIndex: 0,
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                    zIndex: 0,
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                    zIndex: 0,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                    zIndex: 0,
                },
                }}
            >
                {loading && <Loading />}
                {!loading && records &&  (
                <DataGrid
                    rows={records}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    sx={{
                      "& .custom-stretch-row": {
                        height: "auto", // Set height to 'auto' to make the rows stretch
                    },
                    "& .MuiDataGrid-cell": {
                        fontSize: "16px", // Adjust the font size as needed
                        color: "black",
                        overflow:"auto"
                    },
                    "& .MuiDataGrid-columnHeader": {
                        fontSize: "16px",
                        color: "black",
                        fontWeight: "bold", // Adjust the font size as needed
                    },
                    }}
                    checkboxSelection={true}
                />
                )}
            </Box>
        </Box>
    )
  };
  

  