import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: null,
    Cashout: [],
};

const CashoutSlice = createSlice({
    name: "Cashout",
    initialState,
    reducers: {
        CashoutRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        CashoutSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.Cashout = action.payload;
        },
        approvedCashoutSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.Cashout = action.payload;
        },
        CashoutFail: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    },
});

export const CashoutActions = CashoutSlice.actions;
export default CashoutSlice;
